import { Component, Mixins, Model, Prop, Ref } from 'vue-property-decorator'

import { parseDate, formatDate, isValidDate } from '@/lib/date'
import IMask from 'imask'
import validTime from '@/helpers/validTime'
import { isMoscowTimeZone } from '@/helpers/timeZone'
import MoscowTimeMixin from '@/mixins/moscow-time.mixin'

@Component
export default class DateTime extends Mixins(MoscowTimeMixin) {
  @Ref('dateInput') private readonly dateInputRef!: {
    focus: () => void
  }
  @Model('change', { type: String, default: '' })
  private readonly dateValue!: string
  @Prop({ type: Boolean, default: false }) private readonly validate!: boolean
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
  @Prop({ type: Boolean, default: true }) private readonly attach!: boolean
  @Prop({ type: [Number, String], default: '284' })
  private readonly maxWidth!: number | string
  @Prop({ type: String })
  private readonly maxDate?: string
  @Prop({ type: String })
  private readonly minDate?: string
  @Prop({ type: Boolean, default: false }) private readonly autofocus!: boolean
  @Prop({ type: Boolean, default: true }) private readonly isShowInfo!: boolean
  @Prop({ type: Array }) private readonly errorMessages?: string[]

  private showedDatePicker = false

  private optionMask = {
    mask: 'HH:MM',
    overwrite: true,
    lazy: false,
    blocks: {
      HH: {
        mask: IMask.MaskedRange,
        placeholderChar: '0',
        from: 0,
        to: 23,
        maxLength: 2,
      },
      MM: {
        mask: IMask.MaskedRange,
        placeholderChar: '0',
        from: 0,
        to: 59,
        maxLength: 2,
      },
    },
  }

  private get isShowIcon() {
    return this.isShowInfo && !isMoscowTimeZone
  }

  private get rules() {
    return {
      required: (v: string) => !!v,
      time: (v: string) => validTime(v),
    }
  }

  private get parsedDate() {
    const date = this.dateValue
      ? parseDate(this.dateValue, 'yyyy-MM-dd HH:mm:ss', new Date())
      : null

    return date && isValidDate(date) ? date : null
  }

  private set parsedDate(val) {
    const dateValue = val ? formatDate(val, 'yyyy-MM-dd HH:mm:ss') : null

    this.$emit('change', dateValue)
  }

  private get formatedDate() {
    return this.parsedDate?.toLocaleString('ru-RU', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  private get datePickerValue() {
    return this.parsedDate && formatDate(this.parsedDate, 'yyyy-MM-dd')
  }

  private set datePickerValue(val) {
    if (val) {
      const date = parseDate(val, 'yyyy-MM-dd', new Date())
      const [hourStr, minuteStr] = this.timePickerValue?.split(':') || []
      const hour = +hourStr
      const minute = +minuteStr

      if (!isNaN(hour)) {
        date.setHours(hour)
      }

      if (!isNaN(minute)) {
        date.setMinutes(minute)
      }

      this.parsedDate = date
    } else {
      this.parsedDate = null
    }
  }

  private get timeToInfo() {
    return (
      this.moscowTime.toLocaleString('ru-RU', {
        hour: 'numeric',
        minute: 'numeric',
      }) || ''
    )
  }

  private get dateToInfo() {
    return this.moscowTime.toLocaleString('ru-RU', {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  private get timePickerValue() {
    return (
      this.parsedDate?.toLocaleString('ru-RU', {
        hour: 'numeric',
        minute: 'numeric',
      }) || ''
    )
  }

  private set timePickerValue(timeString) {
    if (!this.parsedDate || !timeString) return

    const date = new Date(this.parsedDate.getTime())
    const [hourStr, minuteStr] = timeString.split(':')

    const hour = +hourStr
    const minute = +minuteStr

    if (!isNaN(hour)) {
      date.setHours(Math.min(Math.max(0, hour), 23))
    }

    if (!isNaN(minute)) {
      date.setMinutes(Math.min(Math.max(0, minute), 59))
    }

    this.parsedDate = date
  }

  private get datePickerMin() {
    if (!this.minDate) return

    return formatDate(
      parseDate(this.minDate, 'yyyy-MM-dd HH:mm:ss', new Date()),
      'yyyy-MM-dd'
    )
  }

  private get datePickerMax() {
    if (!this.maxDate) return

    return formatDate(
      parseDate(this.maxDate, 'yyyy-MM-dd HH:mm:ss', new Date()),
      'yyyy-MM-dd'
    )
  }

  private onBlur() {
    this.$emit('blur')
    this.showedDatePicker = false
  }

  private focus() {
    this.dateInputRef.focus()
  }
}
