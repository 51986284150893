var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!_vm.localImages.length)?_c('v-skeleton-loader',{attrs:{"type":"card"}}):_c('div',{class:[
    'image-cropper',
    `image-cropper_place_${_vm.place}`,
    {
      'image-cropper_dynamic-ratio': _vm.isDynamicRatio,
    },
  ]},[(_vm.titleOneAllImages)?_c('div',{staticClass:"d-sm-flex justify-sm-space-between image-cropper__title mb-6"},[_c('h6',{staticClass:"text-h6 text-md-h5 text-lg-h4 mr-sm-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.titleOneAllImages)+" ")])]):_vm._e(),_c('div',{class:[
      'image-cropper__content',
      _vm.backgroundColor,
      {
        'py-4 px-2 px-lg-8 pt-lg-6 pb-lg-8':
          _vm.place === 'default' && !_vm.noGutters,
      },
    ]},[(_vm.switchEnabled)?_c('div',{staticClass:"image-cropper__title-switch d-inline-flex align-center justify-space-between"},[_c('div',{staticClass:"image-cropper__title-switch-label blue-grey--text text--darken-1"},[_vm._v(" Одно изображение на все форматы ")]),_c('v-switch',{staticClass:"mt-0 ml-1 ml-sm-2 pl-0 pt-0",attrs:{"color":"green","flat":"","hide-details":"","inset":""},model:{value:(_vm.localOneImageForAllFormats),callback:function ($$v) {_vm.localOneImageForAllFormats=$$v},expression:"localOneImageForAllFormats"}})],1):_vm._e(),_vm._t("top"),(_vm.place === 'default' && _vm.isActiveCropper && !_vm.hidePreviews)?_c('div',{class:[
        'd-flex',
        'justify-start',
        'image-cropper__previews',
        { 'mb-6': !!_vm.currentImage },
      ]},_vm._l((_vm.localImages),function(image,imageIndex){return _c('ImageCropperPreview',{key:imageIndex,class:['flex-grow-0', 'flex-shrink-0', 'image-cropper__preview'],attrs:{"image":image,"active":imageIndex === _vm.currentImageIndex},on:{"click":function($event){return _vm.onClickPreview($event, imageIndex)},"crop":function($event){return _vm.onCropPreview(imageIndex)},"replace":function($event){return _vm.onReplacePreview(imageIndex)}}})}),1):_vm._e(),_c('div',{class:{ 'deep-purple lighten-5': _vm.place === 'default' }},[(_vm.isShowCloseButton)?_c('v-btn',{staticClass:"ml-auto mr-2 d-block mb-2",attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.closeCropper}},[_c('v-icon',[_vm._v(" mdi-close ")])],1):_vm._e(),(_vm.place === 'default' && !_vm.hiddenControls)?_c('div',{staticClass:"py-5 px-2 px-sm-4 px-dm-5 d-sm-flex flex-md-wrap flex-lg-nowrap align-sm-center"},[_c('div',{class:[
            'd-flex',
            'justify-space-between',
            'image-cropper__controls',
            { 'image-cropper__controls_disabled': !_vm.canSaveImage },
          ]},_vm._l((_vm.controlsConfig),function(control){return _c('div',{key:control.tooltip,staticClass:"flex-grow-0 flex-shrink-0 mr-sm-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"deep-purple"},on:{"click":function($event){return _vm.controlAction(control.action, control.params)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(control.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(control.tooltip))])])],1)}),0),_c('v-slider',{class:[
            'image-cropper__slider',
            'mt-4',
            'mt-sm-0',
            'ml-sm-3',
            'mr-sm-4',
            'image-cropper__controls',
            { 'image-cropper__controls_disabled': !_vm.canSaveImage },
          ],attrs:{"disabled":!_vm.isReadyCropper,"value":_vm.currentZoom * 100,"max":93,"color":"deep-purple","track-color":"blue-grey lighten-4","hide-details":""},on:{"input":_vm.onZoom},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"blue-grey lighten-1"}},[_vm._v(" mdi-magnify-minus-outline ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"blue-grey lighten-1"}},[_vm._v(" mdi-magnify-plus-outline ")])]},proxy:true}],null,false,1838742985)}),(_vm.buttonsEnabled)?_c('div',{staticClass:"image-cropper-buttons d-flex justify-center mt-6 mt-sm-0 mt-md-5 mt-lg-0",style:({
            width: _vm.$vuetify.breakpoint.mdOnly ? '100%' : undefined,
          })},[_c('v-btn',{staticClass:"font-weight-medium flex-grow-0 flex-shrink-0 mx-1",attrs:{"disabled":!_vm.hasChanges,"loading":_vm.loading,"elevation":"0","color":"primary","height":"28"},on:{"click":_vm.onSave}},[_vm._v(" сохранить ")]),_c('v-btn',{staticClass:"font-weight-medium flex-grow-0 flex-shrink-0 mx-1",attrs:{"color":"red","height":"28","outlined":""},on:{"click":_vm.onCancel}},[_vm._v(" отмена ")])],1):_vm._e()],1):_vm._e(),_c('v-responsive',{staticClass:"d-flex align-center",attrs:{"aspect-ratio":_vm.place === 'detailImage' ? _vm.imageContainerAspectRatio : undefined}},[_c('ImageUploader',{attrs:{"uploader-visible":!_vm.currentOriginalImageUrl,"min-width":_vm.minWidthImage,"min-height":_vm.minHeightImage,"title":_vm.title,"info-text":_vm.infoText,"max-size":_vm.maxSize,"image-dinamic-aspect-ratio":_vm.isDynamicRatio},on:{"load":_vm.onLoadImage,"load:start":_vm.onLoadStart},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('v-responsive',{class:[
                'image-cropper__container',
                {
                  'image-cropper__container_disabled': _vm.readonlyCropper,
                },
              ],attrs:{"aspect-ratio":_vm.imageContainerAspectRatio}},[(_vm.isActiveCropper && _vm.currentOriginalImageUrl)?_c('Cropper',{key:`${_vm.cropperKey}_${_vm.currentImageIndex}_${_vm.localOneImageForAllFormats}`,ref:"cropper",staticClass:"image-cropper__cropper",attrs:{"src":_vm.currentOriginalImageUrl,"debounce":false,"transitions":false,"transition-time":0,"canvas":false,"stencilProps":{
                  lines: {},
                  handlers: {},
                  resizable: false,
                  movable: false,
                  scalable: false,
                  aspectRatio: _vm.isDynamicRatio
                    ? undefined
                    : _vm.currentAspectRatio,
                },"resizeImage":{ adjustStencil: false },"default-size":_vm.defaultSize,"default-position":_vm.defaultPosition,"default-boundaries":_vm.defaultBoundaries,"stencil-size":_vm.stencilSize,"background-wrapper-component":_vm.$options.components.CustomBackgroundWrapper,"image-restriction":_vm.isDynamicRatio ? 'fit-area' : 'stencil'},on:{"change":_vm.onChange,"ready":function($event){_vm.isReadyCropper = true}}}):_vm._e()],1)]},proxy:true}])})],1)],1),_vm._t("bottom"),_c('v-expand-transition',[(_vm.place === 'detailImage' && !_vm.hiddenControls && _vm.canSaveImage)?_c('div',[_c('div',{staticClass:"d-flex align-center justify-center image-cropper__controls-wrapper"},[_c('div',{class:[
              'd-flex',
              'justify-space-between',
              'image-cropper__controls',
              'mr-2',
              'mr-sm-3',
              'mr-md-5',
            ]},_vm._l((_vm.controlsConfig),function(control){return _c('div',{key:control.tooltip,staticClass:"flex-grow-0 flex-shrink-0 mr-sm-2"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.controlAction(control.action, control.params)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(control.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(control.tooltip))])])],1)}),0),_c('v-slider',{class:[
              'image-cropper__slider',
              'image-cropper__controls',
              { 'image-cropper__controls_disabled': !_vm.canSaveImage },
            ],attrs:{"disabled":!_vm.isReadyCropper,"value":_vm.currentZoom * 100,"max":93,"color":"primary","track-color":"blue-grey lighten-3","hide-details":""},on:{"input":_vm.onZoom},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{attrs:{"color":"blue-grey lighten-1"}},[_vm._v(" mdi-magnify-minus-outline ")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"blue-grey lighten-1"}},[_vm._v(" mdi-magnify-plus-outline ")])]},proxy:true}],null,false,1838742985)})],1)]):_vm._e()]),(_vm.place === 'detailImage' && _vm.isActiveCropper && !_vm.hidePreviews)?_c('div',{staticClass:"d-flex justify-start image-cropper__previews"},[_c('div',{staticClass:"d-flex justify-center mx-auto flex-shrink-0"},_vm._l((_vm.localImages),function(image,imageIndex){return _c('ImageCropperPreview',{key:imageIndex,staticClass:"flex-grow-0 flex-shrink-0 image-cropper__preview",attrs:{"image":image,"active":imageIndex === _vm.currentImageIndex,"color":"light"},on:{"click":function($event){return _vm.onClickPreview($event, imageIndex)},"crop":function($event){return _vm.onCropPreview(imageIndex)},"replace":function($event){return _vm.onReplacePreview(imageIndex)}}})}),1)]):_vm._e()],2),(_vm.place === 'detailImage' && _vm.isActiveCropper && !_vm.hiddenButtons)?_c('div',{staticClass:"image-cropper-buttons d-flex justify-center justify-sm-end"},[_c('v-btn',{staticClass:"font-weight-medium flex-grow-0 flex-shrink-0 mx-1",attrs:{"outlined":"","color":"red","height":"28"},on:{"click":_vm.onCancel}},[_vm._v(" отмена ")]),_c('v-btn',{staticClass:"font-weight-medium flex-grow-0 flex-shrink-0 mx-1",attrs:{"disabled":!_vm.hasChanges,"loading":_vm.loading,"elevation":"0","color":"primary","height":"28"},on:{"click":_vm.onSave}},[_vm._v(" сохранить ")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }