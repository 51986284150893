import { Component, Vue, Prop } from 'vue-property-decorator'
import { IWidget } from '@/types/widget'
import { Getter } from 'vuex-class'
import { RootGetters } from '@/store/types'
import { Platform } from '@/types/platform'

@Component({
  name: 'WidgetsList',
})
export default class WidgetsList extends Vue {
  @Prop({ type: Array, required: true })
  private readonly widgetItems!: IWidget[]
  @Prop({ type: String, default: '' })
  private readonly title?: string
  @Prop({ type: String, default: '6' }) private readonly itemSize?:
    | number
    | string
  @Getter private readonly getPlatform!: RootGetters['getPlatform']

  private isDisabledLink(item: IWidget) {
    return item.disabled || !item.url
  }

  private isShowLink(platform?: Platform | Platform[]) {
    if (Array.isArray(platform)) {
      return platform.some((item: string) => item === this.getPlatform)
    }

    return !platform || platform === this.getPlatform
  }
}
