import { Vue, Component, Prop } from 'vue-property-decorator'
import * as Sentry from '@sentry/vue'
import { ENABLED_SENTRY } from '@/config/base'
import randomUUID from '@/helpers/randomUUID'
import { visibleObserver } from '@/lib/visible-observer'

@Component
export default class ImageUi extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly contain!: boolean
  @Prop({ type: String }) private readonly src?: string
  @Prop({ type: Number }) private readonly aspectRatio!: number
  @Prop({ type: Boolean, default: false })
  private readonly draggable!: boolean

  private localImageUrl = ''
  private imageWidth = 0
  private imageHeight = 0
  private divId = randomUUID()
  private performanceNowImageLoad = 0

  private localImageLoaded = false

  private get localAspectRatio() {
    if (!this.aspectRatio && !this.localImageLoaded) {
      return 1.5
    }

    return this.aspectRatio
  }

  private async onLoad() {
    const loadedInSeconds = Math.round(
      (performance.now() - this.performanceNowImageLoad) / 1e3
    )

    this.localImageLoaded = true

    if (ENABLED_SENTRY && loadedInSeconds > 30) {
      Sentry.captureException(
        new Error(
          JSON.stringify(
            {
              message: 'Долгая загрузка изображения',
              seconds: loadedInSeconds,
              url: this.src,
            },
            null,
            2
          )
        )
      )
    }
  }

  private mounted() {
    let imageEl: HTMLImageElement | null = null
    let retryLoadImage = 3
    let retryLoadTimeoutId = 0

    const onErrorImage = () => {
      if (!retryLoadImage) {
        return
      }

      window.clearTimeout(retryLoadTimeoutId)

      retryLoadTimeoutId = window.setTimeout(() => {
        loadImage()
      }, 2e3)
    }

    const onLoadImage = () => {
      const $el = this.$el as HTMLDivElement | undefined

      if (!$el?.parentElement || !imageEl) return

      if (imageEl.width < 700 && imageEl.height < 700) {
        URL.revokeObjectURL(this.localImageUrl)
        this.localImageUrl = imageEl.src

        return
      }

      const canvas = document.createElement('canvas')
      const width = Math.min(imageEl.width, $el.offsetWidth + 160)
      const height = width * (imageEl.height / imageEl.width)

      canvas.width = width
      canvas.height = height

      const canvasCtx = canvas.getContext('2d')

      if (!canvasCtx) return

      canvasCtx.imageSmoothingQuality = 'high'
      canvasCtx.imageSmoothingEnabled = true

      canvasCtx.drawImage(imageEl, 0, 0, width, height)

      canvas.toBlob(
        (imageBlob) => {
          if (!imageBlob) return

          URL.revokeObjectURL(this.localImageUrl)

          this.localImageUrl = URL.createObjectURL(imageBlob)

          imageEl?.removeEventListener('load', onLoadImage)
          imageEl?.remove()
          canvas.remove()
          imageEl = null
        },
        'image/jpeg',
        0.8
      )
    }

    let requestAnimationFrameId = 0

    const loadImage = () => {
      const src = this.src

      if (!src) {
        return
      }

      retryLoadImage--
      imageEl = new Image()

      imageEl.addEventListener('load', onLoadImage)
      imageEl.addEventListener('error', onErrorImage)
      imageEl.crossOrigin = 'anonymous'
      imageEl.src = src
    }

    this.$watch(
      () => {
        return this.src
      },
      (src) => {
        if (!src) {
          this.localImageLoaded = false
          this.localImageUrl = ''
          visibleObserver.unobserve(this.divId)
          return
        }

        visibleObserver.observe(this.divId, () => {
          window.cancelAnimationFrame(requestAnimationFrameId)

          requestAnimationFrameId = window.requestAnimationFrame(() => {
            this.performanceNowImageLoad = performance.now()
            this.localImageLoaded = false

            loadImage()
          })
        })
      },
      {
        immediate: true,
      }
    )

    this.$once('hook:beforeDestroy', () => {
      visibleObserver.unobserve(this.divId)
      window.cancelAnimationFrame(requestAnimationFrameId)
      window.clearTimeout(retryLoadTimeoutId)
      imageEl?.removeEventListener('load', onLoadImage)
      imageEl?.removeEventListener('error', onErrorImage)
      imageEl?.remove()
      URL.revokeObjectURL(this.localImageUrl)
    })
  }
}
