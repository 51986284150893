import { Vue, Component, Ref, Prop } from 'vue-property-decorator'

@Component
export default class InfiniteScroll extends Vue {
  @Ref('loader') private readonly loaderRef?: HTMLDivElement
  @Prop({ type: Boolean, default: true }) private readonly hasMore!: boolean
  @Prop({ type: Function, required: true })
  private readonly loadMoreFunc!: () => Promise<unknown>

  private showedLoader = true

  private mounted() {
    let observeTimeoutId = 0

    const loaderObserver = new IntersectionObserver(
      async ([{ isIntersecting }]) => {
        if (!isIntersecting) return

        if (this.loaderRef) {
          loaderObserver.unobserve(this.loaderRef)
        }

        try {
          await this.loadMoreFunc()

          if (this.loaderRef) {
            loaderObserver.observe(this.loaderRef)
          }
        } catch (error) {
          this.showedLoader = false
          console.error(error)
        }
      },
      {
        threshold: 0,
      }
    )

    this.$watch(
      () => {
        return this.showedLoader
      },
      (showedLoader) => {
        window.clearTimeout(observeTimeoutId)

        observeTimeoutId = window.setTimeout(() => {
          if (!this.loaderRef) return

          if (showedLoader) {
            loaderObserver.observe(this.loaderRef)
          } else {
            loaderObserver.unobserve(this.loaderRef)
          }
        }, 10)
      },
      {
        immediate: true,
      }
    )

    this.$once('hook:beforeDestroy', () => {
      window.clearTimeout(observeTimeoutId)
      loaderObserver.disconnect()
    })
  }
}
