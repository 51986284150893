var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'image-uploader',
    `image-uploader_place_${_vm.place}`,

    {
      'image-uploader_invalid': _vm.error,
      'image-uploader_valid': !_vm.error,
      'image-uploader_rounded': _vm.rounded,
      'image-uploader-disabled': _vm.disabled,
    },
  ],style:({ '--aspect-ratio': _vm.aspectRatio }),on:{"dragover":_vm.onDragover,"dragleave":_vm.onDragleave,"drop":_vm.onDrop}},[_vm._t("prepend"),(_vm.uploaderVisible)?[(_vm.title)?_c('h4',{staticClass:"image-uploader__title blue-grey--text text--darken-3"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('div',{class:[
        'image-uploader-zone',
        `image-uploader-zone_${_vm.place}`,
        {
          'image-uploader-zone_dragged': _vm.dragged,
        },
      ]},[_c('div',{staticClass:"image-uploader-zone__inner text-center pa-3"},[(_vm.isShowSpinner)?_c('v-progress-circular',{attrs:{"size":_vm.iconSize,"indeterminate":"","color":"blue-grey lighten-1","width":"1"}}):_c('v-icon',{attrs:{"size":_vm.iconSize,"color":"blue-grey lighten-1"}},[_vm._v(" mdi-wallpaper ")]),(!_vm.hideChangeButton)?_c('div',{staticClass:"image-uploader-zone__button-zone"},[_c('div',{class:[
              'blue-grey--text',
              'text--darken-2',
              'font-weight-regular',
              {
                'text-body-2': ![
                  'new-expert-widget',
                  'new-adv-expert-widget',
                ].includes(_vm.place),
                'text-caption-1': [
                  'new-expert-widget',
                  'new-adv-expert-widget',
                ].includes(_vm.place),
                'text-caption-2': ['new-adv-expert-widget'].includes(_vm.place),
                'mt-2': !['new-adv-expert-widget'].includes(_vm.place),
              },
            ]},[_vm._v(" "+_vm._s(['new-expert-widget', 'new-adv-expert-widget'].includes(_vm.place) ? 'Загрузить изображение' : 'Чтобы загрузить изображение')+" ")]),_c('v-btn',{staticClass:"font-weight-bold mt-2 image-uploader__upload-button",attrs:{"outlined":"","small":"","color":"deep-purple","height":"24"}},[_c('input',{key:_vm.inputFileKey,attrs:{"accept":_vm.localAcceptFiles.join(','),"multiple":"","type":"file"},on:{"change":_vm.onChangeFile}}),_vm._v(" выберите файл ")])],1):_vm._e(),_c('div',{class:[
            'image-uploader-zone__input-zone',
            'blue-grey--text',
            'text--darken-2',
            {
              'text-body-2 mt-4 px-2': ![
                'citation-widget',
                'new-expert-widget',
                'new-adv-expert-widget',
                'video-preview',
                'video-edit-preview',
                'native-post',
              ].includes(_vm.place),
            },
          ]},[_vm._v(" Перетащите картинку сюда или "),_c('label',{staticClass:"image-uploader-zone__file-input deep-purple--text"},[_vm._v(" выберите "),(_vm.place === 'citation-widget')?_c('br'):_vm._e(),_vm._v(" файл "),_c('input',{key:_vm.inputFileKey,attrs:{"accept":_vm.localAcceptFiles.join(','),"multiple":"","type":"file"},on:{"change":_vm.onChangeFile}})])])],1)]),(_vm.localInfoText)?_c('p',{staticClass:"image-uploader__info text-center blue-grey--text text--lighten-2 mt-2 mb-0 font-weight-500"},[_vm._v(" "+_vm._s(_vm.localInfoText)+" ")]):_vm._e()]:_vm._e(),(!_vm.uploaderVisible)?_vm._t("image"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }