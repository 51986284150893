import axios, { CancelTokenSource } from 'axios'
import {
  Vue,
  Component,
  Prop,
  PropSync,
  Ref,
  Watch,
} from 'vue-property-decorator'
import { formatDate, parseDate } from '@/lib/date'
import debounce from 'lodash.debounce'
import { Getter } from 'vuex-class'
import { FilterField, FilterFieldItem } from '@/types/filter'
import { getDefaultSort, getSortField, getSortType } from '@/helpers/tableSort'
import htmlToText from '@/helpers/htmlToText'
import uniqCid from '@/helpers/uniqCid'
import cloneDeep from '@/helpers/cloneDeep'
import { RootGetters } from '@/store/types'

interface ComboboxItem extends FilterField {
  filterValue: string
  filterText: string
  withoutSymbol?: boolean
}

const cleanSearchText = (value: string) =>
  value
    .trim()
    .replace(/[^\wа-яА-ЯёЁ\s\d]/g, ' ')
    .replace(/\s{2,}/g, ' ')

@Component
export default class FilteredSearchBlock extends Vue {
  @Getter('getPlatform') private readonly platform!: RootGetters['getPlatform']

  @Ref('combobox') private readonly comboboxRef?: {
    updateMenuDimensions: () => void
    lazySearch: string | null
  }
  @Ref('submitButton') private readonly submitButtonRef?: {
    $el: HTMLButtonElement
  }
  @PropSync('loading', { type: Boolean, default: false })
  private syncedLoading!: boolean
  @Prop({ type: Boolean, default: false }) private readonly noGutters!: boolean
  @Prop({ type: Boolean, default: false }) private readonly breakXs!: boolean
  @Prop({ type: Array, default: () => [] }) private readonly sortFields!: {
    text: string
    value: string
  }[]
  @Prop({ type: Array, required: true })
  private readonly filterFields!: FilterField[]
  @Prop({ type: Array })
  private readonly filterFieldItems?: FilterFieldItem[]
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
  @Prop({ type: String, default: getDefaultSort() })
  private readonly initialSort!: string
  @Prop({ type: String, default: '' })
  private readonly usedFor!: string
  @Prop({ type: Boolean, default: false })
  private readonly onlyOneDayDatePicker!: boolean

  private datePicker: {
    range: boolean
    value: string | string[] | null
  } = {
    range: true,
    value: null,
  }

  private comboboxClassName = `combobox-${uniqCid()}`
  private nudgeLeftMenu = 0
  private sortType = 'asc'
  private sortField = ''
  private comboboxVal: ComboboxItem[] = []
  private searchInputVal: string | null = null
  private searchInputEl?: HTMLInputElement | null
  private _cancelToken: CancelTokenSource | null = null
  private showDropdown = true

  private get sortOff() {
    return this.sortFields.length === 0
  }

  private get localFilterFieldItems() {
    if (Array.isArray(this.filterFieldItems)) {
      const searchInputVal =
        this.searchInputVal &&
        cleanSearchText(this.searchInputVal).toLowerCase()

      return searchInputVal
        ? this.filterFieldItems.filter(({ text }) => {
            text = cleanSearchText(text).toLowerCase()

            return (
              text.includes(searchInputVal) || searchInputVal.includes(text)
            )
          })
        : this.filterFieldItems
    }

    return null
  }

  private get currentFieldType() {
    return this.searchMode
      ? this.comboboxVal[this.currentFieldIndex]?.fieldType
      : undefined
  }

  private get searchMode() {
    return (
      this.comboboxVal.length > 0 &&
      !this.comboboxVal[this.currentFieldIndex].filterValue
    )
  }

  private get localFilterFields() {
    return this.filterFields.filter(({ text }) => !!text)
  }

  private get disabledSearchInput() {
    return (
      this.localFilterFields.length === this.comboboxVal.length &&
      !this.searchMode
    )
  }

  private get comboboxItems() {
    return !this.searchMode && !this.syncedLoading ? this.localFilterFields : []
  }

  private get currentFieldIndex() {
    const index = this.comboboxVal.findIndex(({ filterValue }) => !filterValue)

    return index > -1 ? index : this.comboboxVal.length - 1
  }

  private htmlToText(str: string) {
    return htmlToText(str)
  }

  private setFilter({
    field,
    value,
    text,
    filter,
  }: {
    field: string
    text: string
    value: string
    filter?: string
  }) {
    const newItems = this.comboboxVal.filter(
      ({ filterValue, fieldName }) => !!filterValue && fieldName !== field
    )

    this.onInputCombobox(
      (
        this.localFilterFields.filter(
          ({ fieldName }) => fieldName === field
        ) as ComboboxItem[]
      ).concat(newItems),
      false
    )

    this.setFilterValue({ value, text, autofocus: false, filter })
  }

  private onChangeSort() {
    const isType =
      this.sortField.includes('.desc') || this.sortField.includes('.asc')

    const value =
      this.sortField && this.sortType
        ? `${this.sortField}${isType ? '' : `.${this.sortType}`}`
        : ''
    this.$emit('change:sort', value)
  }

  @Watch('currentFieldIndex')
  private onChangeCurrentFieldIndex() {
    const item = this.comboboxVal[this.currentFieldIndex]

    if (item) {
      this.moveSearchInputEl(this.currentFieldIndex)
      this.$emit('change:filter-field', item)
    }

    this.datePicker.value = null
  }

  @Watch('comboboxVal.length')
  private onChangeComboboxValLength(newLength: number, oldLength: number) {
    if (newLength < oldLength) {
      this.onSubmitFilterForm({
        searchInputVal: '',
      })
    }
  }

  // Изменение значения фильтра
  private changeFilterValue(item: ComboboxItem) {
    const currentComboboxItem = this.comboboxVal[this.currentFieldIndex]
    this.isShowDropdown(item)

    const searchInputVal = this.searchInputVal || ''
    // Ищем значение фильтра по значению input в текущем списке
    const currentFilterFieldItem =
      searchInputVal &&
      this.filterFieldItems?.find(({ text }) =>
        text.toLowerCase().includes(searchInputVal?.trim().toLowerCase())
      )

    if (currentComboboxItem && !currentComboboxItem.filterValue) {
      if (currentFilterFieldItem) {
        currentComboboxItem.filterValue = currentFilterFieldItem.value
        currentComboboxItem.filterText = currentFilterFieldItem.text
      } else {
        currentComboboxItem.filterValue = searchInputVal
        currentComboboxItem.filterText = searchInputVal
      }
    }

    // Удаляем фильтр без значения
    if (!currentComboboxItem.filterValue) {
      this.deleteComboboxVal(currentComboboxItem)
    }

    this.searchInputVal = this.htmlToText(item.filterText)
    item.filterValue = ''
    item.filterText = ''
    this.moveSearchInputEl()
  }

  // Перемещаем input перед редактируемым значением фильтра
  private async moveSearchInputEl(fieldIndex = this.comboboxVal.length - 1) {
    await this.$nextTick()

    const itemEl = this.$el.querySelectorAll('.filtered-search-block__item')[
      fieldIndex
    ]

    if (this.searchInputEl && itemEl) {
      itemEl.insertAdjacentElement('afterend', this.searchInputEl)
      this.moveMenu()
    }
  }

  // Перемещает меню v-combobox к активному фильтру
  private async moveMenu() {
    await this.$nextTick()
    this.comboboxRef?.updateMenuDimensions()
    const inputEl = this.$el.querySelector<HTMLElement>(
      '.v-select__selections input'
    )

    const comboboxEl = document.body.querySelector<HTMLElement>(
      `.${this.comboboxClassName}`
    )

    if (comboboxEl && inputEl) {
      comboboxEl.style.setProperty('--input-width', `${inputEl.offsetWidth}px`)
    }

    console.log('comboboxEl', comboboxEl)

    this.nudgeLeftMenu = inputEl?.offsetLeft || 0
  }

  private toggleSortType() {
    this.sortType = this.sortType === 'asc' ? 'desc' : 'asc'
  }

  // Получение параметра filter для API в формате [column.operator]=[value]
  private getFilter(fullKey: string, fullVal: string | number) {
    if (fullKey.includes('||')) {
      const formatedFilter = fullKey
        .split('||')
        .map((key) => `${key}=${fullVal}`)
        .join(',')
      return `(,${formatedFilter},)`
    }

    const val =
      typeof fullVal === 'string' &&
      !fullVal.includes('||') &&
      !fullKey.includes('.match')
        ? fullVal.split(' ')
        : [fullVal]

    const separators = ['&', ',']
    const separator =
      separators.find((v) => fullKey.includes(v)) || separators[0]

    return fullKey
      .split(separator)
      .reduce<string[]>((acc, key, index, list) => {
        const value = val[index]

        if (val[index] === undefined) return acc

        if (!key) {
          acc.push(fullVal.toString())

          return acc
        }

        if (list.length === 1) {
          acc.push(`${key}=${fullVal}`)

          return acc
        }

        acc.push(`${key}=${value}`)

        return acc
      }, [])
      .join(separator)
  }

  private onChangeDatePickerRange(oneDay: boolean) {
    if (
      oneDay ||
      !Array.isArray(this.datePicker.value) ||
      !this.datePicker.value.length
    ) {
      return
    }

    this.onChangeDate(this.datePicker.value[0])
  }

  private onChangeDate(value: string | string[]) {
    const text = Array.isArray(value)
      ? value
          .map((str) =>
            parseDate(str, 'yyyy-MM-dd', new Date()).toLocaleDateString(
              'ru-RU',
              {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              }
            )
          )
          .join('—')
      : parseDate(value, 'yyyy-MM-dd', new Date()).toLocaleDateString('ru-RU', {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        })

    if (Array.isArray(value)) {
      value.sort(
        (a, b) =>
          parseDate(a, 'yyyy-MM-dd', new Date()).getTime() -
          parseDate(b, 'yyyy-MM-dd', new Date()).getTime()
      )
    } else {
      value = [value, value]
    }

    value = value.map((str, index) => {
      const date = parseDate(str, 'yyyy-MM-dd', new Date())

      if (index > 0) {
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
      }

      return formatDate(date, 'yyyy-MM-dd HH:mm:ss')
    })

    this.setFilterValue({ value: value.join('||'), text })
  }

  private setFilterValue({
    value,
    text,
    filter,
    autofocus = true,
  }: {
    value: string
    text: string
    filter?: string
    autofocus?: boolean
  }) {
    const item = this.comboboxVal[this.currentFieldIndex]
    item.filterKey = filter || item.filterKey
    item.filterValue = value
    item.filterText = text ?? item.filterText

    this.$emit('change:filter-field', item)

    this.searchInputVal = null
    this.moveMenu()

    if (autofocus) {
      this.focusCombobox()
    }

    this.onSubmitFilterForm({
      searchInputVal: '',
    })
  }

  private deleteComboboxVal(item: ComboboxItem) {
    this.comboboxVal = this.comboboxVal.filter(
      ({ fieldName }) => fieldName !== item.fieldName
    )

    // this.searchInputVal = null

    this.moveMenu()
    this.$emit('delete:filter-field', item.fieldName)
    this.onSubmitFilterForm({
      searchInputVal: '',
    })
  }

  private onEnterCombobox() {
    let searchInputVal = this.searchInputVal

    if (!searchInputVal?.length) {
      this.onSubmitFilterForm()

      return
    }

    const newComboboxVal = [...this.comboboxVal]

    const item = this.comboboxVal.find(({ filterText }) => !filterText)

    if (item) {
      const comboboxItem = {
        ...item,
        filterValue: searchInputVal,
        filterText: searchInputVal,
      }

      const newComboboxValIndex = newComboboxVal.findIndex(
        ({ fieldName }) => fieldName === item.fieldName
      )

      if (newComboboxValIndex > -1) {
        newComboboxVal.splice(newComboboxValIndex, 1, comboboxItem)
      } else {
        newComboboxVal.push(comboboxItem)
      }

      this.onInputCombobox(newComboboxVal)
      searchInputVal = ''
    }

    this.onChangeSearchInputVal(searchInputVal)
    this.onSubmitFilterForm({
      searchInputVal,
    })
  }

  private onInputCombobox(items: (ComboboxItem | string)[], autofocus = true) {
    this.moveMenu()

    this.comboboxVal = items.reduce((acc: ComboboxItem[], item) => {
      // Добавляем название фильтра с пустыми значениями
      if (typeof item !== 'string') {
        acc.push(
          cloneDeep({
            ...item,
            // Значение, которое отправляется в API для параметра filter
            filterValue: item.filterValue || '',
            // Значение, которое выводится пользователю
            filterText: item.filterText || '',
          })
        )
      }
      return acc
    }, [])

    if (
      autofocus &&
      items.length > 0 &&
      typeof items[items.length - 1] !== 'string'
    ) {
      this.focusCombobox()
    }
    this.searchInputVal = null
  }

  private onUpdateSearchInput(value: string | null) {
    this.searchInputVal = value === null ? this.searchInputVal : value

    this.$nextTick(() => {
      if (this.comboboxRef) {
        this.comboboxRef.lazySearch = this.searchInputVal
      }

      if (!this.comboboxVal.length) {
        // @ts-ignore
        this.focusCombobox.cancel()
      }
    })
  }

  private isShowDropdown(item: ComboboxItem) {
    this.showDropdown = item?.autocomplete ?? true
  }

  private onChangeCombobox(items: ComboboxItem[]) {
    this.isShowDropdown(items[this.currentFieldIndex])

    this.$nextTick(() => {
      const lastItem = items[items.length - 1]

      if (typeof lastItem === 'string' && !this.disabledSearchInput) {
        // Сохраняем значение поиска, т.к. v-combobox делает сброс значения input при blur событии
        this.searchInputVal = lastItem
      }
    })
  }

  // Отправка формы
  private onSubmitFilterForm(
    params: {
      searchInputVal?: string
    } = {}
  ) {
    const { searchInputVal = this.searchInputVal } = params

    this.comboboxVal = this.comboboxVal.filter(
      ({ filterValue }) => !!filterValue
    )
    this.searchInputVal = searchInputVal

    const filter = this.comboboxVal.reduce(
      (acc: string[], { filterKey, filterValue, withoutSymbol }) => {
        if (withoutSymbol) {
          const formatedValue = cleanSearchText(filterValue)

          acc.push(this.getFilter(filterKey, formatedValue))
          return acc
        }

        acc.push(this.getFilter(filterKey, filterValue))
        return acc
      },
      []
    )

    let searchText = ''

    if (!filter.length && searchInputVal) {
      const searchByText = this.filterFields.some(
        ({ fieldName }) => fieldName === 'searchByText'
      )

      if (searchByText) {
        searchText = searchInputVal
      } else {
        const filterFieldKey =
          this.filterFields.find(({ fieldName }) => fieldName === 'name')
            ?.filterKey || 'name.like'

        filter.push(`${filterFieldKey}=${searchInputVal}`)
      }
    }

    this.$emit('change:filter', filter.join(','), searchText)
  }

  private onChangeSearchInputVal(newStr: string | null) {
    if (!newStr || newStr.length < 3) {
      return
    }

    this._cancelToken?.cancel()
    this._cancelToken = axios.CancelToken.source()

    if (
      !this.comboboxVal.length ||
      this.comboboxVal[this.comboboxVal.length - 1].filterValue
    ) {
      this.syncedLoading = false
      return
    }

    const { fieldName, filterKey, withoutSymbol } =
      this.comboboxVal[this.currentFieldIndex]

    let formatedStr = ''

    if (newStr) {
      formatedStr = withoutSymbol ? cleanSearchText(newStr) : newStr.trim()
    }
    // Поиск данных по фильтру
    this.$emit('check:filter', {
      field: fieldName,
      value: newStr,
      filter: this.getFilter(filterKey, formatedStr),
      cancelToken: this._cancelToken?.token,
    })
  }

  private clearCombobox() {
    this.comboboxVal = []
    this.searchInputVal = null
    this.moveMenu()
    this.onSubmitFilterForm({
      searchInputVal: '',
    })
  }

  private focusCombobox() {
    this.$nextTick(() => {
      // Вызываем клик на input для фокусировки на v-combobox с открытым меню
      this.searchInputEl?.click()
      this.searchInputEl?.focus()
    })
  }

  private initLocalStorage() {
    if (!this.usedFor) return

    const onChangeFilterParams = debounce(
      () => {
        const newFullPath = this.$router.resolve({
          query: {
            ...this.$route.query,
            comboboxVal: this.comboboxVal.map(
              ({
                fieldName,
                filterKey,
                filterText,
                filterValue,
                text,
                withoutSymbol,
              }) =>
                [
                  `fieldName:=${fieldName}`,
                  `filterText:=${filterText}`,
                  `filterKey:=${filterKey}`,
                  `filterValue:=${filterValue}`,
                  `text:=${text}`,
                  withoutSymbol ? `withoutSymbol:=${withoutSymbol}` : '',
                ].join(';')
            ),
            sortType: this.sortType,
            sortField: this.sortField,
            searchText: this.searchInputVal || undefined,
          },
        }).resolved.fullPath

        if (newFullPath !== this.$route.fullPath) {
          this.$router.replace(newFullPath)
        }

        localStorage.setItem(
          storageKey,
          JSON.stringify({
            comboboxVal: this.comboboxVal,
            sortType: this.sortType,
            sortField: this.sortField,
          })
        )
      },
      500,
      { maxWait: 500 }
    )

    this.$watch(() => {
      return this.comboboxVal
        .map(({ filterValue }) => filterValue)
        .concat([
          this.sortType,
          this.sortField,
          this.searchInputVal?.trim() || '',
        ])
        .join('-')
    }, onChangeFilterParams)

    const storageKey = `searchBlock.${this.platform}.${this.usedFor}`
    const jsonDataFromStorage = localStorage.getItem(storageKey)

    if (jsonDataFromStorage) {
      const dataFromStorage = JSON.parse(jsonDataFromStorage)

      this.comboboxVal = dataFromStorage.comboboxVal
      this.sortType = dataFromStorage.sortType
      this.sortField = dataFromStorage.sortField
    }

    const {
      comboboxVal,
      sortType = this.sortType,
      sortField = this.sortField,
      searchText: querySearchText,
    } = this.$route.query

    const searchText =
      typeof querySearchText === 'string'
        ? querySearchText.trim()
        : this.searchInputVal

    if (typeof sortType === 'string') {
      this.sortType = sortType
    }

    if (typeof sortField === 'string') {
      this.sortField = sortField
    }

    if (comboboxVal) {
      const parsedComboboxVal = (
        Array.isArray(comboboxVal) ? comboboxVal : [comboboxVal]
      ).reduce<ComboboxItem[]>((newComboboxVal, item) => {
        if (!item) return newComboboxVal

        const newComboboxItem = item
          .split(';')
          .reduce<Record<string, string>>((comboboxItem, entry) => {
            const [key, value] = entry.split(':=')

            comboboxItem[key] = value
            return comboboxItem
          }, {})

        newComboboxVal.push(newComboboxItem as unknown as ComboboxItem)

        return newComboboxVal
      }, [])

      this.comboboxVal = parsedComboboxVal
    }

    if (this.comboboxVal.length > 0 || searchText?.length) {
      this.onSubmitFilterForm({
        searchInputVal: searchText || '',
      })
    }

    if (typeof searchText === 'string') {
      this.searchInputVal = searchText
    }

    this.$once('hook:beforeDestroy', () => {
      onChangeFilterParams.cancel()
    })
  }

  private created() {
    if (this.onlyOneDayDatePicker) {
      this.datePicker.range = false
    }

    this.sortField =
      this.sortFields.find((item) => item.value.includes(this.initialSort))
        ?.value || getSortField(this.initialSort)

    this.sortType = getSortType(this.initialSort)
    this.$watch('sortType', this.onChangeSort)
    this.$watch('sortField', this.onChangeSort)
    this.focusCombobox = debounce(this.focusCombobox.bind(this), 100)

    this.$watch('searchInputVal', debounce(this.onChangeSearchInputVal, 500))
    this.initLocalStorage()
  }

  private mounted() {
    this.searchInputEl = this.$el.querySelector(
      '.v-select__selections input'
    ) as HTMLInputElement | null

    this.$watch(
      () => {
        return this.syncedLoading
      },
      () => {
        this.moveMenu()
      }
    )
  }

  private beforeDestroy() {
    this._cancelToken?.cancel()
    this.syncedLoading = false
  }
}
