var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("default"),_c('span',{class:[
      'text-caption-1 d-block mt-1 text-none font-weight-regular',
      {
        'blue-grey--text text--lighten-2': _vm.count >= 0,
        'red--text': _vm.count < 0,
      },
    ]},[_vm._v(" "+_vm._s(_vm.counterInfo)+" ")])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }