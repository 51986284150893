import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { Preview } from 'vue-advanced-cropper'
import { CropperSizeImage } from '@/types/cropper'

@Component({
  components: {
    Preview,
  },
})
export default class ImageCropperPreview extends Vue {
  @Prop({ type: Object, required: true })
  private readonly image!: CropperSizeImage
  @Prop({ type: Boolean, default: false }) private readonly active!: boolean
  @Prop({ type: String, default: 'grey' }) private readonly color!: string
  @Prop({ type: Boolean, default: false })
  private readonly hiddenControls!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hiddenTitle!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hiddenDesc!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly descInTooltip!: boolean

  private isReady = false

  private get canReplace() {
    this.$vuetify.breakpoint.xsOnly
    return !!this.image.transform.image?.url
  }

  private get iconColor() {
    if (this.active) {
      return 'deep-purple accent-3'
    }

    if (this.image.transform.image?.url) {
      return 'green'
    }

    return 'blue-grey lighten-4'
  }

  private get titleClassName() {
    if (this.active) {
      return 'deep-purple--text text--accent-3'
    }

    return 'blue-grey--text text--darken-3'
  }

  private get currentAspectRatio() {
    return this.image?.aspectRatio
      ? this.image?.aspectRatio
      : this.image.transform.crop_w && this.image.transform.crop_h
      ? this.image.transform.crop_w / this.image.transform.crop_h
      : 1
  }

  private mounted() {
    this.$nextTick(() => {
      this.isReady = true
    })
  }
}
