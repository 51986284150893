var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'pa-4': !_vm.noGutters }},[_c('v-row',{staticClass:"filtered-search-block",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":_vm.breakXs ? 8 : 12,"md":_vm.sortOff ? 12 : 8}},[_c('div',{staticClass:"d-flex align-center font-weight-bold caption blue-grey--text text--darken-3 mb-1"},[_vm._v(" Поиск или фильтрация "),_vm._t("append-search-label")],2),_c('v-form',{staticClass:"d-flex align-start filtered-search-block__form",on:{"submit":function($event){$event.preventDefault();return _vm.onEnterCombobox.apply(null, arguments)}}},[_c('v-combobox',{ref:"combobox",class:[
            'filtered-search-block__form-combobox',
            {
              'filtered-search-block__form-combobox_disabled':
                _vm.disabledSearchInput,
            },
          ],attrs:{"value":_vm.comboboxVal,"hide-selected":"","search-input":_vm.searchInputVal,"append-icon":null,"menu-props":{
            allowOverflow: true,
            nudgeLeft: -_vm.nudgeLeftMenu,
            minWidth: 140,
            rounded: 'sm t-0',
            maxHeight: 425,
            closeOnContentClick: !_vm.searchMode,
            contentClass: _vm.disabledSearchInput
              ? `filtered-search-block-combobox-menu filtered-search-block__form-combobox_hide ${_vm.comboboxClassName}`
              : `filtered-search-block-combobox-menu ${_vm.comboboxClassName}`,
          },"items":_vm.comboboxItems,"disabled":_vm.disabled,"loader-height":"3","multiple":"","hide-details":"","outlined":"","dense":"","clearable":false,"item-value":"fieldName"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnterCombobox.apply(null, arguments)},"input":_vm.onInputCombobox,"change":_vm.onChangeCombobox,"update:search-input":_vm.onUpdateSearchInput},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.searchInputVal || _vm.comboboxVal.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"disabled":_vm.disabled,"color":"primary","size":"24"},on:{"click":_vm.clearCombobox}},[_vm._v(" mdi-close ")])],1)]}}],null,false,1643274117)},[_c('span',[_vm._v("Отменить поиск")])]):_vm._e()]},proxy:true},(_vm.showDropdown)?{key:"no-data",fn:function(){return [(_vm.syncedLoading)?_c('span',{staticClass:"px-4"},[_vm._v(" Поиск... ")]):(_vm.searchMode)?_c('div',[(_vm.currentFieldType === 'datepicker')?_c('v-card',{staticClass:"filtered-search-block__datepicker",attrs:{"elevation":"0","max-width":"290"}},[_c('DatePicker',{attrs:{"first-day-of-week":1,"range":_vm.datePicker.range,"full-width":"","color":"primary","locale":"ru-RU"},on:{"change":_vm.onChangeDate},model:{value:(_vm.datePicker.value),callback:function ($$v) {_vm.$set(_vm.datePicker, "value", $$v)},expression:"datePicker.value"}}),(!_vm.onlyOneDayDatePicker)?_c('v-checkbox',{staticClass:"mt-1 mb-3 mx-4",attrs:{"true-value":false,"false-value":true,"hide-details":"","dense":"","label":"Поиск по одному дню"},on:{"change":_vm.onChangeDatePickerRange},model:{value:(_vm.datePicker.range),callback:function ($$v) {_vm.$set(_vm.datePicker, "range", $$v)},expression:"datePicker.range"}}):_vm._e()],1):(
                  !!_vm.localFilterFieldItems &&
                  (!_vm.searchInputVal || _vm.searchInputVal.length >= 2)
                )?_c('div',[(_vm.localFilterFieldItems.length > 0)?_c('div',_vm._l((_vm.localFilterFieldItems),function(serchFieldValue,i){return _c('v-list-item',{key:`${serchFieldValue.value}_${i}`,on:{"click":function($event){return _vm.setFilterValue({
                        value: serchFieldValue.value,
                        text: serchFieldValue.text,
                        filter: serchFieldValue.filter,
                      })}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{domProps:{"innerHTML":_vm._s(serchFieldValue.text)}})])],1)],1)}),1):_c('span',{staticClass:"px-4"},[_vm._v(" Ничего не найдено ")])]):_c('span',{staticClass:"px-4"},[_vm._v(" Начните ввод для поиска ")])],1):(_vm.comboboxVal.length > 0)?_c('span',{staticClass:"px-4"},[_vm._v(" Ничего не найдено ")]):(false)?_c('span',{staticClass:"px-4"},[_vm._v(" Нажмите Enter для начала поиска ")]):_vm._e()]},proxy:true}:null,{key:"selection",fn:function({ item, selected }){return [(item.text)?_c('div',{staticClass:"filtered-search-block__item"},[_c('div',{class:[
                  'subtitle-2',
                  'ma-1',
                  'grey',
                  'grey--text',
                  'text--darken-4',
                  'px-1',
                  'd-flex',
                  'align-center',
                  { 'lighten-2': !selected, 'lighten-1': selected },
                ]},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.changeFilterValue(item)}}},[_vm._v(" "+_vm._s(item.text)+" = "+_vm._s(_vm.htmlToText(item.filterText || item.filterValue))+" ")]),_c('v-icon',{staticClass:"ml-1 flex-grow-0 flex-shrink-0",attrs:{"small":""},on:{"click":function($event){return _vm.deleteComboboxVal(item)}}},[_vm._v(" mdi-close ")])],1)]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"filtered-search-block__form-btn-wrap"},[_c('v-btn',{ref:"submitButton",staticClass:"rounded-l-0 filtered-search-block__form-btn d-block px-0",attrs:{"disabled":_vm.disabled,"block":"","type":"submit","outlined":"","color":"rgba(0, 0, 0, 0.4)"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-magnify ")])],1)],1)],1)],1),(!_vm.sortOff)?_c('v-col',{attrs:{"cols":"12","sm":_vm.breakXs ? 4 : 12,"md":"4"}},[_c('div',{staticClass:"font-weight-bold caption blue-grey--text text--darken-3 mb-1"},[_vm._v(" Сортировка ")]),_c('v-form',{staticClass:"d-flex"},[_c('v-select',{attrs:{"menu-props":{ minWidth: 80 },"items":_vm.sortFields,"disabled":_vm.disabled,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.sortField),callback:function ($$v) {_vm.sortField=$$v},expression:"sortField"}}),(false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"filtered-search-block__form-btn-wrap"},'div',attrs,false),on),[_c('v-btn',{staticClass:"rounded-l-0 filtered-search-block__form-btn",attrs:{"block":"","outlined":"","disabled":_vm.disabled,"color":"rgba(0, 0, 0, 0.4)"},on:{"click":_vm.toggleSortType}},[_c('v-icon',{attrs:{"color":"rgba(0, 0, 0, 0.65)"}},[_vm._v(" "+_vm._s(`mdi-sort-${_vm.sortType}ending`)+" ")])],1)],1)]}}],null,false,2809067719)},[_c('span',[_vm._v(_vm._s(_vm.sortType === 'desc' ? 'По возрастанию' : 'По убыванию')+" ")])]):_vm._e()],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }