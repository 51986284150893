import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import { formatDate, parseDate } from '../../lib/date'

@Component({
  inheritAttrs: false,
})
export default class DatePicker extends Vue {
  @Model('input') private readonly value!: string | string[]
  @Prop({ type: Boolean, default: false }) private readonly range!: boolean
  @Prop({ type: Boolean, default: false }) private readonly noTitle!: boolean

  private activePicker = ''

  private get localListeners() {
    const $listeners = { ...this.$listeners }

    delete $listeners.input
    delete $listeners.change

    return $listeners
  }

  private get isHiddenBtn() {
    return this.activePicker === 'YEAR'
  }

  private onInput(value: string | string[]) {
    if (Array.isArray(value)) {
      if (value.length > 1) {
        const startDate = parseDate(value[0], 'yyyy-MM-dd', new Date())
        const endDate = parseDate(value[1], 'yyyy-MM-dd', new Date())

        if (startDate.getTime() > endDate.getTime()) {
          value = value.concat().reverse()
        }
      }
    }

    this.$emit('input', value)
    this.$emit('change', value)
  }

  private setTodayValue() {
    const value = this.range
      ? [
          formatDate(new Date(), 'yyyy-MM-dd'),
          formatDate(new Date(), 'yyyy-MM-dd'),
        ]
      : formatDate(new Date(), 'yyyy-MM-dd')

    this.$emit('input', value)
    this.$emit('change', value)
  }
}
