/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import debounce from 'lodash.debounce'
import { TransformableImage } from 'vue-advanced-cropper'

export default {
  components: {
    TransformableImage,
  },
  props: ['touchMove', 'mouseMove', 'touchResize', 'wheelResize'],
  data() {
    return {
      notificationVisible: false,
      notificationType: null,
    }
  },
  created() {
    this.hideNotifications = debounce(() => {
      this.notificationVisible = false
    }, 1000)
  },
  methods: {
    eventsFilter(nativeEvent, transforming) {
      if (
        nativeEvent.type === 'touchstart' ||
        nativeEvent.type === 'touchmove'
      ) {
        if (nativeEvent.touches.length === 1 && !transforming) {
          this.notificationVisible = true
          this.notificationType = 'touch'
          this.hideNotifications()
          return false
        } else {
          this.notificationVisible = false
        }
      } else if (nativeEvent.type === 'wheel') {
        if (!transforming && !nativeEvent.ctrlKey) {
          this.notificationVisible = true
          this.notificationType = 'wheel'
          this.hideNotifications()
          return false
        } else {
          this.notificationVisible = false
        }
      }
      nativeEvent.preventDefault()
      nativeEvent.stopPropagation()
    },
  },
}
