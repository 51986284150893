import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=4ad3b46f&scoped=true"
import script from "./Editor.ts?vue&type=script&lang=ts&external"
export * from "./Editor.ts?vue&type=script&lang=ts&external"
import style0 from "./Editor.scss?vue&type=style&index=0&id=4ad3b46f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ad3b46f",
  null
  
)

export default component.exports