import { BreadCrumb } from '@/types/breadCrumb'
import { RootGetters } from '@/store/types'
import { Component, Vue } from 'vue-property-decorator'

import { Getter } from 'vuex-class'
import { platformsSettings } from '@/config/platformConfig'
import { articleModule, podcastModule, episodeModule } from '@/store/namespaces'
import { ArticleGetters } from '@/store/modules/article/types'
import { PodcastGetters } from '@/store/modules/podcast/types'
import { EpisodeGetters } from '@/store/modules/episode/types'
import htmlToText from '@/helpers/htmlToText'

@Component
export default class BreadCrumbs extends Vue {
  @articleModule.Getter('articleName')
  private readonly articleNameGetter!: ArticleGetters['articleName']
  @podcastModule.Getter('podcastName')
  private readonly podcastNameGetter!: PodcastGetters['podcastName']
  @episodeModule.Getter('episodeName')
  private readonly episodeNameGetter!: EpisodeGetters['episodeName']
  @Getter private readonly getPlatform!: RootGetters['getPlatform']

  private get platformParams() {
    return platformsSettings.find(
      (platform) => platform.slug === this.getPlatform
    )
  }

  private get breadcrumbsItems() {
    const countVisible = 3
    const breadcrumbs = this.$route.path
      .replace(/\/$/, '')
      .replace(/^\//, '')
      .split('/')
      .reduce((acc: BreadCrumb[], item, index, arr) => {
        const path = `/${arr.slice(0, index + 1).join('/')}`
        const to = this.$router.resolve({ path }).resolved
        const text: string | undefined = to.meta?.breadcrumb?.name

        if (typeof text === 'string') {
          acc.push({
            text,
            link: true,
            disabled: index === arr.length - 1,
            to,
            exact: true,
          })
        }
        return acc
      }, [])

    // Срез до <countVisible> последних элеменов массива крошек
    const items = breadcrumbs.slice(
      Math.max(breadcrumbs.length - countVisible, 0),
      breadcrumbs.length
    )

    const routeNameMapConfig: Record<string, string> = {
      content_article: this.articleNameGetter,
      content_podcast: this.podcastNameGetter,
      content_episode: this.episodeNameGetter,
    }

    const articleNameGetter =
      this.$route.name && routeNameMapConfig[this.$route.name]
        ? htmlToText(routeNameMapConfig[this.$route.name])
        : ''

    if (articleNameGetter) {
      items.push({
        text: articleNameGetter,
        link: true,
        disabled: true,
        to: undefined,
      })
    }

    return items
  }
}
