import { Getter } from 'vuex-class'
import { Vue, Component, Model, Ref, Prop } from 'vue-property-decorator'
import { imageSources } from '@/config/image'
import { RootGetters } from '@/store/types'
import htmlToText from '@/helpers/htmlToText'

@Component
export default class ImageSourceCombobox extends Vue {
  @Ref('combobox') private readonly comboboxRef?: {
    activateMenu: () => void
  }
  @Model('input', { type: String }) private readonly value!: string | null
  @Getter private readonly getPlatform!: RootGetters['getPlatform']
  @Prop({ type: Boolean, default: false }) private readonly autofocus!: boolean
  @Prop({ type: String, default: '' }) private readonly placeholder!: string
  @Prop({ type: String, default: '' }) private readonly selectionLabel!: string

  private localValue: string | null = ''
  private isFocused = false
  private lazyIsFocused = false
  private blurTimeoutId = 0

  private get items() {
    return imageSources({ platform: this.getPlatform })
  }

  private get localListeners() {
    const $listeners = { ...this.$listeners }

    delete $listeners['update:search-input']
    delete $listeners['input']
    delete $listeners['focus']
    delete $listeners['blur']

    return $listeners
  }

  private onUpdateSearchInput(value: string | null) {
    value = value?.trim() ?? null

    if (this.isFocused) {
      this.$emit('input', htmlToText(value))
    }
    this.$emit('update:search-input', value)
  }

  private onInput(value: string | null) {
    this.localValue = value

    this.$emit('input', value)
  }

  private onBlur(event: Event) {
    window.clearTimeout(this.blurTimeoutId)

    this.isFocused = false
    this.blurTimeoutId = window.setTimeout(() => {
      this.lazyIsFocused = false
    }, 1500)

    this.$emit('blur', event)
  }

  private onFocus(event: Event) {
    window.clearTimeout(this.blurTimeoutId)
    this.isFocused = true
    this.lazyIsFocused = true
    this.$emit('focus', event)
  }

  private created() {
    this.localValue = htmlToText(this.value)

    this.$watch(
      () => {
        return [this.lazyIsFocused, this.value].join('-')
      },
      () => {
        if (this.lazyIsFocused) return

        this.localValue = this.value
      }
    )
  }

  private mounted() {
    this.$watch(
      () => {
        return this.autofocus
      },
      (autofocus) => {
        if (!autofocus) return

        this.comboboxRef?.activateMenu()
      },
      {
        immediate: true,
      }
    )
  }

  private beforeDestroy() {
    window.clearTimeout(this.blurTimeoutId)
  }
}
