import { Vue, Component, Prop, Model } from 'vue-property-decorator'

interface ButtonProps {
  text: string
  onClick: () => void
  height: string
  loading?: boolean
  disabled?: boolean
}

@Component
export default class ConfirmDialog extends Vue {
  @Model('input', { type: Boolean, default: false })
  private readonly showed!: boolean

  @Prop({ type: String, default: '360' }) private maxWidth!: string
  @Prop({ type: Object, required: true })
  private readonly confirmButtonProps!: ButtonProps
  @Prop({ type: Object, required: true })
  private readonly cancelButtonProps!: ButtonProps

  private get isMobile() {
    return this.$vuetify.breakpoint.smAndDown
  }
}
