import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import uniqCid from '@/helpers/uniqCid'

import { SizeImage } from '@/types/image'
import SiriusAPI from '@/api'

@Component({
  inheritAttrs: false,
})
export default class ImageUploader extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean
  @Prop({ type: Boolean, default: false }) private readonly rounded!: boolean
  @Prop({ type: Boolean, default: true })
  private readonly uploaderVisible!: boolean

  @Prop({ type: String, default: '' }) private readonly title!: string
  @Prop({ type: Array }) private readonly acceptFiles!: string[]
  @Prop({ type: String, default: '' }) private readonly infoText!: string
  @Prop({ type: Boolean, default: false })
  private readonly hiddenInfoText!: string
  @Prop({ type: String, default: 'content' })
  private readonly place!: string
  @Prop({ type: Number, default: 0 }) private readonly minWidth!: number
  @Prop({ type: Number, default: 0 }) private readonly minHeight!: number
  @Prop({ type: Number, default: 15728640 }) private maxSize!: number
  @Prop({ type: Boolean, default: false }) private readonly error!: boolean
  @Prop({ type: Function, default: null })
  private readonly uploadFunction!: (payload: { file: File }) => void
  @Prop({ type: Boolean, default: false })
  private readonly hideChangeButton!: boolean
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
  @Prop({ type: Number }) private readonly aspectRatio?: number
  @Prop({ type: Boolean, default: false })
  private readonly imageDinamicAspectRatio!: boolean

  private dragged = false
  private checking = false
  private pendingRequests: number[] = []
  private inputFileKey = Date.now()

  private get isShowSpinner() {
    return this.pendingRequests.length > 0 || this.checking || this.loading
  }

  private get localAcceptFiles() {
    return Array.isArray(this.acceptFiles)
      ? this.acceptFiles
      : ['image/jpeg', 'image/gif', 'image/png']
  }

  private get iconSize() {
    if (this.place === 'new-expert-widget') {
      return 24
    }

    if (this.place === 'new-adv-expert-widget') {
      return this.$vuetify.breakpoint.lgAndUp ? 32 : 24
    }

    if (this.place === 'content') {
      return this.$vuetify.breakpoint.xsOnly ? 24 : 40
    }

    if (this.place === 'verstka-editor') {
      return this.$vuetify.breakpoint.xsOnly ? 24 : 40
    }

    if (this.place === 'popup-page') {
      return this.$vuetify.breakpoint.smAndDown ? 24 : 40
    }

    if (this.place === 'native-post') {
      return this.$vuetify.breakpoint.xsOnly ? 32 : 40
    }

    if (this.place === 'citation-widget') {
      return 40
    }

    if (this.place === 'media-library' || this.place === 'video-preview') {
      return 30
    }

    return this.$vuetify.breakpoint.xsOnly ? 24 : 40
  }

  private get localInfoText() {
    if (this.hiddenInfoText) return ''

    if (this.infoText) return this.infoText

    if (!this.minWidth && !this.minHeight) return ''

    const infoParts = ['Формат изображения — JPG, PNG, GIF']

    if (this.imageDinamicAspectRatio) {
      infoParts.push(
        `картинка может быть любой пропорции, но ширина изображения должна быть не менее ${this.minWidth} пикселей, а высота - не менее ${this.minHeight} пикселей`
      )
    } else {
      infoParts.push(`минимальные размеры — ${this.minWidth}x${this.minHeight}`)
    }

    infoParts.push(
      `максимальный вес — ${(this.maxSize / 1024 / 1024).toLocaleString(
        'ru-RU'
      )} Mb`
    )

    return `${infoParts.join('; ')}.`
  }

  private onDragover(e: DragEvent) {
    e.preventDefault()

    this.dragged = true
  }

  private onDragleave(e: DragEvent) {
    e.preventDefault()
    this.dragged = false
  }

  private async onDrop(e: DragEvent) {
    e.preventDefault()
    this.dragged = false
    const imageJSON = e.dataTransfer?.getData('siriusImage')
    const image = imageJSON && (JSON.parse(imageJSON) as SizeImage)

    if (image) {
      this.$emit('load:start')
      this.checking = true

      const checked = await this.checkImg(image)

      if (checked) {
        await this.setImage(image)
      }

      this.checking = false
    } else if (e.dataTransfer?.files.length) {
      this.setFiles(Array.from(e.dataTransfer.files))
    }
  }

  private onChangeFile(e: Event) {
    const inputFile = e.target as HTMLInputElement

    if (!inputFile.files) return

    this.setFiles(Array.from(inputFile.files))
  }

  private async setFiles(files: File[]) {
    this.inputFileKey++
    this.$emit('load:start')
    for (const file of files) {
      await this.setFile(file)
    }
  }

  private async checkFile(file: File | null) {
    return await new Promise<boolean>((resolve) => {
      if (
        !file ||
        !this.localAcceptFiles.includes(file.type) ||
        file.size > this.maxSize
      ) {
        this.$notify({
          type: 'error',
          title: 'Размер картинки больше рекомендуемого',
        })
        // resolve(false)
        resolve(true)
      } else {
        resolve(true)
      }
    })
  }

  private checkImg(value: string | SizeImage) {
    return new Promise<boolean>((resolve) => {
      if (!this.minWidth && !this.minHeight) {
        resolve(true)

        return
      }

      const check = ({ width, height }: { width: number; height: number }) => {
        const correctImg = width >= this.minWidth && height >= this.minHeight

        if (!correctImg) {
          this.$notify({
            type: 'error',
            title: 'Размер загруженной картинки меньше рекомендуемого',
          })
        }

        // resolve(correctImg)
        resolve(true)
      }

      if (typeof value === 'string') {
        const img = new Image()

        img.src = value

        img.onload = () => {
          check({
            width: img.width,
            height: img.height,
          })
        }

        img.onerror = () => {
          resolve(false)
        }
      } else {
        check({
          width: value.width,
          height: value.width,
        })
      }
    })
  }

  private async addImage(file: File) {
    if (!file) return

    const requestId = uniqCid()

    this.pendingRequests.push(requestId)

    const data = new FormData()

    data.append('file', file)

    const fileName = file.name

    try {
      if (this.uploadFunction) {
        await this.uploadFunction({
          file,
        })
      } else {
        const { item } = await SiriusAPI.image.addImage({ file: data })
        await this.setImage(item)
      }
    } catch (err) {
      this.$notify({
        type: 'error',
        title: `Произошла ошибка при загрузке изображения ${fileName}`,
      })
      console.error(err)
    } finally {
      this.pendingRequests = this.pendingRequests.filter(
        (id) => id !== requestId
      )
    }
  }

  private setImage(
    value: Omit<SizeImage, 'height' | 'width'> &
      Partial<Pick<SizeImage, 'height' | 'width'>>
  ) {
    return new Promise((resolve) => {
      if (value.width && value.height) {
        this.$emit('load', value)

        resolve(null)

        return
      }

      const img = new Image()

      img.src = value.url

      img.onload = () => {
        this.$emit('load', {
          ...value,
          width: img.width,
          height: img.height,
        })

        resolve(null)
      }
    })
  }

  private async setFile(file: File | null) {
    if (!file) return

    this.checking = true

    try {
      const checkedFile = await this.checkFile(file)
      const objectURL = URL.createObjectURL(file)
      const checkedImg = checkedFile && (await this.checkImg(objectURL))

      const checked = checkedFile && checkedImg

      URL.revokeObjectURL(objectURL)

      if (checked) {
        await this.addImage(file)
      }
    } finally {
      this.checking = false
    }
  }
}
