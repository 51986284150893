import debounce from 'lodash.debounce'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class BaseCombobox extends Vue {
  @Ref('combobox') private readonly comboboxRef?: {
    lazyValue?: unknown
    focus: () => void
    updateMenuDimensions: () => void
    clearableCallback: () => void
    updateCombobox: () => void
  }
  @Ref('infinityLoaderPagination')
  private readonly infinityLoaderPaginationRef?: HTMLDivElement

  @Prop({ type: [String, Number, Array] }) private readonly value?: unknown
  @Prop({ type: Boolean, default: false })
  private readonly infinityLoaderPagination!: boolean
  @Prop({ type: Array }) private readonly items?: unknown[]
  @Prop({ type: Boolean, default: true }) private readonly outlined!: boolean
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean
  @Prop({ type: Boolean, default: true }) private readonly dense!: boolean
  @Prop({ type: Boolean, default: false }) private readonly multiple!: boolean
  @Prop({ type: String, default: 'text' }) private readonly itemText!: string
  @Prop({ type: String, default: 'value' }) private readonly itemValue!: string
  @Prop({ type: String, default: '' }) private readonly placeholder!: string
  @Prop({ type: Boolean, default: true }) private selectionFromItems!: boolean
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean
  @Prop({ type: Function })
  private readonly infinityLoadFunction?: (payload: {
    page: number
    search: string
    count: number
  }) => Promise<{ items: unknown[] }>

  private debouncedInfinityLoaderSearch!: ReturnType<typeof debounce>
  private autoUpdateValue!: ReturnType<typeof debounce>

  private isFocused = false

  private infinityLoader: {
    searching: boolean
    hasMore: boolean
    loading: boolean
    page: number
    count: number
    items: unknown[]
    search: string
    searchKey: number
    inited: boolean
  } = {
    searching: false,
    page: 1,
    count: 10,
    hasMore: true,
    loading: false,
    items: [],
    search: '',
    searchKey: Date.now(),
    inited: false,
  }

  private get isShowInfinityLoaderPagination() {
    return (
      this.infinityLoader.hasMore &&
      this.localItems.length > 0 &&
      !this.infinityLoader.searching
    )
  }

  private get localLoading() {
    return (
      this.infinityLoader.searching ||
      this.infinityLoader.loading ||
      this.loading
    )
  }

  private get usedInfinityLoader() {
    return !!this.infinityLoadFunction
  }

  private get localItems() {
    if (this.usedInfinityLoader) {
      return this.infinityLoader.items
    }

    return this.items || []
  }

  private get localListeners() {
    const $listeners = { ...this.$listeners }

    delete $listeners.input
    delete $listeners.focus
    delete $listeners.blur

    return $listeners
  }

  private get noDataText() {
    if (this.localLoading) {
      return 'Поиск...'
    }

    if (!this.infinityLoader.search.length) {
      return 'Начните ввод для поиска'
    }

    return 'Ничего не найдено'
  }

  private initInfinityLoader() {
    if (!this.usedInfinityLoader) return

    const loadItems = async ({
      page,
      search,
      count,
    }: {
      page: number
      search: string
      count: number
    }) => {
      if (
        !search ||
        this.infinityLoader.loading ||
        !this.infinityLoadFunction
      ) {
        return
      }

      this.infinityLoader.loading = true

      try {
        const { items } = await this.infinityLoadFunction({
          page,
          search,
          count,
        })

        this.infinityLoader.searching = false
        this.infinityLoader.loading = false

        const changedParams = [
          {
            oldValue: page,
            newValue: this.infinityLoader.page,
          },
          {
            oldValue: search,
            newValue: this.infinityLoader.search,
          },
          {
            oldValue: count,
            newValue: this.infinityLoader.count,
          },
        ].some(({ oldValue, newValue }) => oldValue !== newValue)

        if (!changedParams) {
          this.infinityLoader.items =
            page === 1 ? [] : this.infinityLoader.items

          items.forEach((item) => {
            this.infinityLoader.items.push(Object.freeze(item))
          })

          this.infinityLoader.hasMore = count <= items.length
        } else {
          loadItems({
            page: this.infinityLoader.page,
            search: this.infinityLoader.search,
            count: this.infinityLoader.count,
          })
        }
      } catch (err) {
        this.infinityLoader.searching = false
        this.infinityLoader.loading = false

        console.error(err)
      }
    }

    let intervalId = 0
    let visibleObserver: IntersectionObserver | null = null

    if (this.infinityLoaderPagination) {
      visibleObserver = new IntersectionObserver(
        ([{ isIntersecting }]) => {
          if (!isIntersecting || this.localLoading) {
            return
          }

          this.infinityLoader.page++
        },
        {
          threshold: 0,
        }
      )

      intervalId = window.setInterval(() => {
        if (!this.infinityLoaderPaginationRef) return

        window.clearInterval(intervalId)
        this.infinityLoader.inited = true
      }, 500)
    } else {
      this.infinityLoader.inited = true
    }

    this.$watch(
      () => {
        return [
          this.localItems.length,
          this.localLoading,
          this.infinityLoader.inited,
        ].join('-')
      },
      () => {
        const infinityLoaderPaginationRef = this.infinityLoaderPaginationRef

        if (!infinityLoaderPaginationRef) return

        visibleObserver?.unobserve(infinityLoaderPaginationRef)
        visibleObserver?.observe(infinityLoaderPaginationRef)
      },
      {
        immediate: true,
      }
    )

    this.$watch(
      () => {
        return [
          this.infinityLoader.searchKey,
          this.infinityLoader.page,
          this.infinityLoader.count,
        ].join('-')
      },
      () => {
        loadItems({
          page: this.infinityLoader.page,
          search: this.infinityLoader.search,
          count: this.infinityLoader.count,
        })
      }
    )

    this.$once('hook:beforeDestroy', () => {
      visibleObserver?.disconnect()
      window.clearInterval(intervalId)
    })
  }

  private onInput(value: unknown | unknown[]) {
    if (!this.selectionFromItems) {
      this.$emit('input', value)

      return
    }

    if (Array.isArray(value)) {
      this.$emit(
        'input',
        value.filter((v) => typeof v !== 'string')
      )

      this.autoUpdateValue()

      return
    }

    if (typeof value === 'object') {
      this.$emit('input', value)

      this.autoUpdateValue()
      return
    }

    this.comboboxRef?.clearableCallback()
  }

  private onFocus(event: FocusEvent) {
    this.isFocused = true
    this.$emit('focus', event)
  }

  private onBlur(event: FocusEvent) {
    this.isFocused = false
    this.$emit('blur', event)
  }

  private onUpdateSearchInput(value: string | null) {
    value = value?.trim() || this.infinityLoader.search

    if (value !== this.infinityLoader.search) {
      this.infinityLoader.searching = this.infinityLoader.inited
      this.infinityLoader.hasMore = true
      this.infinityLoader.page = 1
      this.infinityLoader.items = []
      this.debouncedInfinityLoaderSearch()
    }

    this.infinityLoader.search = value

    this.$emit('update:search-input', value)
  }

  private focus() {
    this.comboboxRef?.focus()
  }

  private created() {
    this.debouncedInfinityLoaderSearch = debounce(() => {
      this.infinityLoader.searchKey++
    }, 600)

    this.autoUpdateValue = debounce(() => {
      if (!this.comboboxRef) {
        return
      }

      this.comboboxRef.lazyValue = this.value
    }, 50)
  }

  private mounted() {
    const onScroll = () => {
      this.comboboxRef?.updateMenuDimensions()
    }

    this.$watch(
      () => {
        return this.isFocused
      },
      (isFocused) => {
        if (!isFocused) {
          window.removeEventListener('scroll', onScroll)
        } else {
          window.addEventListener('scroll', onScroll, { passive: true })
        }
      },
      { immediate: true }
    )

    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', onScroll)
    })
  }

  private beforeDestroy() {
    this.debouncedInfinityLoaderSearch.cancel()
    this.autoUpdateValue.cancel()
  }
}
