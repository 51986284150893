import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class EditorContainer extends Vue {
  @Prop({ type: String, default: '' })
  private readonly sideTitle!: boolean
  @Prop({ type: String, default: '' })
  private readonly topBarTitle!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hoverPublishButton!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hideSideScrollbar!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hideViewButton!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hideSaveButton!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hideTopbar!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly noStyleContent!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly noStyleSidebar!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hidePublishButton!: boolean
  @Prop({ type: Object }) private readonly saveButtonProps?: Record<string, any>
  @Prop({ type: Object }) private readonly viewButtonProps?: Record<string, any>
  @Prop({ type: Object }) private readonly linkButtonProps?: Record<string, any>
  @Prop({ type: Object }) private readonly historyDetailButtonProps?: Record<
    string,
    any
  >
  @Prop({ type: String, default: '' })
  private readonly place!: string
  @Prop({ type: Object }) private readonly publishButtonProps?: Record<
    string,
    any
  >
  @Prop({ type: Boolean, default: true }) private isOverflowSidebar!: boolean
  @Prop({ type: Boolean, default: false }) private hideSideTitle!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hideHistoryDetailButton!: boolean

  private showedSide = false

  private get localViewButtonProps() {
    const { to, href, target } = {
      to: undefined,
      href: undefined,
      target: undefined,
      ...this.viewButtonProps,
    }

    return { to, href, target }
  }

  private get localLinkButtonProps() {
    const { loading, disabled } = {
      loading: false,
      disabled: false,
      ...this.linkButtonProps,
    }

    return { loading, disabled }
  }

  private get localLinkButtonListeners() {
    const { onClick } = { onClick: () => '', ...this.linkButtonProps }

    return { click: onClick }
  }

  private get publishButtonText() {
    return this.publishButtonProps?.text || ''
  }

  private get publishButtonIcon() {
    return this.publishButtonProps?.icon || ''
  }

  private get localPublishButtonProps() {
    const {
      loading = false,
      disabled = false,
      color = 'primary',
      minWidth = undefined,
    } = {
      ...this.publishButtonProps,
    }

    return {
      loading,
      disabled,
      color,
      minWidth,
    }
  }

  private get localPublishButtonListeners() {
    const { onClick = undefined } = {
      ...this.publishButtonProps,
    }

    const listeners: Record<string, unknown> = {}

    if (onClick) {
      listeners.click = onClick
    }

    return listeners
  }

  private get localSaveButtonProps() {
    const { loading, disabled, outlined, color } = {
      loading: false,
      disabled: false,
      outlined: true,
      color: 'primary',
      ...this.saveButtonProps,
    }

    return {
      loading,
      disabled,
      outlined,
      color,
    }
  }

  private get localSaveButtonListeners() {
    const { onClick } = { onClick: () => '', ...this.saveButtonProps }

    return { click: onClick }
  }

  private get localHistoryDetailButtonListeners() {
    const { onClick } = { onClick: () => '', ...this.historyDetailButtonProps }

    return { click: onClick }
  }

  private get saveButtonText() {
    return this.saveButtonProps?.text || 'Сохранить'
  }

  private get defineSide() {
    return !!this.$slots.side
  }

  private toggleSide(showed = !this.showedSide) {
    this.showedSide = showed
  }
}
