import RequestStatus from '@/types/requestStatus'
import { formatDate } from '@/lib/date'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class Topbar extends Vue {
  @Prop({ type: String, required: true, default: RequestStatus.NOT_STARTED })
  private readonly requestStatusUpdate!: RequestStatus

  @Prop({ type: String, required: true, default: RequestStatus.NOT_STARTED })
  private readonly requestStatusFetch!: RequestStatus

  @Prop({ type: Boolean, default: false })
  private readonly disabledSaveButton!: boolean

  private startSaveDate = formatDate(new Date(), 'dd.MM.yyyy HH:mm')
  private timeoutId = 0

  private get saveDate() {
    return this.isSuccess ? formatDate(new Date(), 'dd.MM.yyyy HH:mm') : ''
  }

  private get isLoadingUpdate() {
    return this.requestStatusUpdate === RequestStatus.PENDING
  }

  private get isSuccess() {
    return this.requestStatusUpdate === RequestStatus.SUCCESS
  }

  private get isNotStart() {
    return this.requestStatusUpdate === RequestStatus.NOT_STARTED
  }

  private get isError() {
    return this.requestStatusUpdate === RequestStatus.FAILED
  }

  private get isLoading() {
    return this.requestStatusFetch === RequestStatus.PENDING
  }

  @Watch('isLoadingUpdate')
  private deleteBtnStatus() {
    if (this.isLoadingUpdate) return

    window.clearTimeout(this.timeoutId)

    this.timeoutId = window.setTimeout(() => {
      this.$emit('delete:btn-status', RequestStatus.NOT_STARTED)
      this.timeoutId = 0
    }, 2e3)
  }

  private onClickSave() {
    this.$emit('save')
  }
}
