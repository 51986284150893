var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-responsive',_vm._g({class:[
    'image-wrapper',
    {
      'image-wrapper_defined_aspect-ratio': !!_vm.localAspectRatio,
    },
  ],attrs:{"id":_vm.divId,"aspect-ratio":_vm.localAspectRatio}},_vm.$listeners),[(!_vm.localImageLoaded)?_c('v-skeleton-loader',{style:({
      position: 'absolute',
      top: 0,
      left: 0,
    }),attrs:{"type":"image","width":"100%","height":"100%"}}):_vm._e(),_c('img',{class:[
      'image',
      {
        image_contain: _vm.contain,
        image_cover: !_vm.contain,
        image_loaded: _vm.localImageLoaded,
      },
    ],attrs:{"src":_vm.localImageUrl,"draggable":_vm.draggable},on:{"load":_vm.onLoad}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }