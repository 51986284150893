import { Prop, Component, Vue } from 'vue-property-decorator'
import capitalizeFirstLetter from '@/helpers/capitalizeFirstLetter'
import numWord from '@/helpers/numWord'
import getCountLetters from '@/helpers/getCountLetters'
import debounce from 'lodash.debounce'

@Component
export default class CounterValue extends Vue {
  @Prop({ type: String, default: '' }) private readonly value!: string
  @Prop({ type: Number, default: 25 }) private readonly maxlength!: number

  private count = 0

  private get counterInfo() {
    return `${capitalizeFirstLetter(
      numWord(this.count, ['остался', 'осталось', 'осталось'])
    )} ${this.count} ${numWord(this.count, ['символ', 'символа', 'символов'])}`
  }

  private mounted() {
    const debouncedChangeCount = debounce(
      (value: string) => {
        this.count = this.maxlength - getCountLetters(value)
      },
      150,
      { maxWait: 150 }
    )

    this.$watch(
      () => {
        return this.value
      },
      debouncedChangeCount,
      { immediate: true }
    )

    this.$once('hook:beforeDestroy', () => {
      debouncedChangeCount.cancel()
    })
  }
}
