var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'image-cropper-preview',
    'd-flex',
    'flex-column',
    'cursor-pointer',
    `image-cropper-preview_color_${_vm.color}`,
    {
      'image-cropper-preview_active': _vm.active,
      'image-cropper-preview_empty-img': !_vm.image.transform.image.url,
      'image-cropper-preview_defined-img': !!_vm.image.transform.image.url,
    },
  ],on:{"click":function($event){return _vm.$emit('click', $event)}}},[(!_vm.hiddenDesc && _vm.descInTooltip)?_c('v-menu',{attrs:{"open-on-focus":false,"open-on-hover":"","top":"","transition":"slide-y-reverse-transition","open-delay":"100","close-delay":"100","nudge-top":"-48px","content-class":"elevation-0 py-1 overflow-hidden"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"cursor-pointer"},'div',attrs,false),on),[(!_vm.hiddenTitle)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"flex-grow-0 flex-shrink-0",attrs:{"color":_vm.iconColor,"size":"20"}},[_vm._v(" mdi-check-circle-outline ")]),_c('div',{class:[
              'ml-1',
              'text-caption-1',
              'font-weight-bold',
              _vm.titleClassName,
            ]},[_vm._v(" "+_vm._s(_vm.image.title)+" ")])],1):_vm._e(),_c('div',{staticClass:"image-cropper-preview__size blue-grey--text text--lighten-2 mb-1"},[_vm._v(" Размер "+_vm._s(`${_vm.image.width}x${_vm.image.height}`)+" ")]),_c('v-responsive',{staticClass:"image-cropper-preview__wrapper flex-grow-0 flex-shrink-0",attrs:{"aspect-ratio":_vm.currentAspectRatio}},[(_vm.isReady)?_c('Preview',{attrs:{"fill":"","coordinates":{
              left: _vm.image.transform.crop_x,
              top: _vm.image.transform.crop_y,
              width: _vm.image.transform.crop_w,
              height: _vm.image.transform.crop_h,
            },"image":{
              src: _vm.image.transform.image.url,
            }}}):_vm._e()],1)],1)]}}],null,false,2985555118)},[_c('span',{staticClass:"text-subtitle-2 white--text rounded py-2 px-4 d-inline-block",style:({ backgroundColor: 'rgba(0, 0, 0, 0.6)' })},[_vm._v(" "+_vm._s(_vm.image.description)+" ")])]):[(!_vm.hiddenTitle)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"flex-grow-0 flex-shrink-0",attrs:{"color":_vm.iconColor,"size":"20"}},[_vm._v(" mdi-check-circle-outline ")]),_c('div',{class:[
          'ml-1',
          'text-caption-1',
          'font-weight-bold',
          _vm.titleClassName,
        ]},[_vm._v(" "+_vm._s(_vm.image.title)+" ")])],1):_vm._e(),(!_vm.hiddenDesc && !_vm.descInTooltip)?_c('div',{staticClass:"text-caption-3 blue-grey--text"},[_vm._v(" "+_vm._s(_vm.image.description)+" ")]):_vm._e(),_c('div',{staticClass:"text-caption-3 blue-grey--text text--lighten-2 mb-2"},[(_vm.image.size)?[_vm._v(" "+_vm._s(_vm.image.size)+" ")]:[_vm._v(" Размер "+_vm._s(`${_vm.image.width}x${_vm.image.height}`)+" ")]],2),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-responsive',{staticClass:"image-cropper-preview__wrapper mt-auto flex-grow-0 flex-shrink-0",attrs:{"aspect-ratio":_vm.currentAspectRatio}},[(_vm.isReady)?_c('Preview',{attrs:{"fill":"","coordinates":{
            left: _vm.image.transform.crop_x,
            top: _vm.image.transform.crop_y,
            width: _vm.image.transform.crop_w,
            height: _vm.image.transform.crop_h,
          },"image":{
            src: _vm.image.transform.image.url,
          }}}):_vm._e(),_c('div',{class:[
            'justify-center',
            'image-cropper-preview__buttons',
            {
              'image-cropper-preview__buttons_showed': hover,
              'd-flex': !_vm.hiddenControls,
              'd-none': _vm.hiddenControls,
            },
          ]},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.canReplace),expression:"canReplace"}],staticClass:"image-cropper-preview__button",attrs:{"color":"deep-purple","fab":"","height":"24","width":"24"},on:{"click":function($event){return _vm.$emit('replace')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","size":"16"}},[_vm._v(" mdi-sync ")])],1)]}}],null,true)},[_c('span',[_vm._v(" заменить изображение ")])]),(false)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.active),expression:"!active"}],staticClass:"image-cropper-preview__button",attrs:{"color":"deep-purple","fab":"","height":"24","width":"24"},on:{"click":function($event){return _vm.$emit('crop')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white","size":"16"}},[_vm._v(" mdi-crop ")])],1)]}}],null,true)},[_c('span',[_vm._v(" вырезать ")])]):_vm._e()],1)],1)]}}])})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }