var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-combobox',_vm._g(_vm._b({ref:"combobox",staticClass:"base-combobox",attrs:{"value":_vm.value,"items":_vm.localItems,"loading":_vm.localLoading,"outlined":_vm.outlined,"dense":_vm.dense,"item-text":_vm.itemText,"item-value":_vm.itemValue,"multiple":_vm.multiple,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur,"~focus":function($event){return _vm.initInfinityLoader.apply(null, arguments)},"update:search-input":_vm.onUpdateSearchInput},scopedSlots:_vm._u([{key:"selection",fn:function({ item, parent }){return [_c('div',{staticClass:"d-flex flex-wrap mr-2 align-center",style:({
        marginTop: '2px',
        marginBottom: '2px',
      })},[_c('span',{class:[
          'pr-1 text-caption',
          { 'primary--text': !_vm.disabled, 'text--disabled': _vm.disabled },
        ]},[_vm._t("selection-item-text",function(){return [_vm._v(" "+_vm._s(item[_vm.itemText])+" ")]},{"item":item})],2),_c('v-icon',{attrs:{"disabled":_vm.disabled,"color":"primary","small":""},on:{"click":function($event){_vm.multiple ? parent.selectItem(item) : parent.clearableCallback()}}},[_vm._v(" $delete ")])],1)]}},(_vm.usedInfinityLoader)?{key:"no-data",fn:function(){return [_c('span',{staticClass:"pa-3 d-block base-combobox__no-data"},[_vm._v(" "+_vm._s(_vm.noDataText)+" ")])]},proxy:true}:null,(_vm.usedInfinityLoader && _vm.infinityLoaderPagination)?{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowInfinityLoaderPagination),expression:"isShowInfinityLoaderPagination"}],ref:"infinityLoaderPagination",staticClass:"text-center base-combobox__infinity-loader"},[_c('v-skeleton-loader',{attrs:{"type":"list-item"}})],1)]},proxy:true}:null],null,true)},'v-combobox',_vm.$attrs,false),_vm.localListeners))
}
var staticRenderFns = []

export { render, staticRenderFns }