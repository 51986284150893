import axios, { Canceler } from 'axios'
import debounce from 'lodash.debounce'
import { Component, Model, Prop, Ref, Vue } from 'vue-property-decorator'
import { Cropper } from 'vue-advanced-cropper'

import {
  CropperImage,
  CropperImageCoordinates,
  CropperImageProp,
  CropperInstance,
  CropperVisibleArea,
} from '@/types/cropper'

import {
  Image,
  ImageTransform,
  ImageTransformModelId,
  SizeImage,
} from '@/types/image'
import cloneObject from '@/helpers/cloneObject'
import isEmpty from '@/helpers/isEmpty'

import ImageCropperPreview from './ImageCropperPreview/ImageCropperPreview.vue'
import CustomBackgroundWrapper from './CustomBackgroundWrapper/CustomBackgroundWrapper.vue'
import SiriusAPI from '@/api'
import nestedFreeze from '@/helpers/nestedFreeze'

@Component({
  components: {
    Cropper,
    CustomBackgroundWrapper,
    ImageCropperPreview,
  },
})
export default class ImageCropper extends Vue {
  @Model('change', { type: Number, default: -1 }) private value!: number
  @Prop({ type: Number }) private readonly containerAspectRatio!: number
  @Prop({ type: Boolean, default: false }) private readonly loading!: boolean
  @Prop({ type: Number })
  private readonly imageTransformModelId?: ImageTransformModelId
  @Prop({ type: Number })
  private readonly imageTransformItemId!: number
  @Prop({ type: Boolean, default: false })
  private readonly selectedPreview!: boolean
  @Prop({ type: String, default: 'blue-grey lighten-5' })
  private readonly backgroundColor!: string
  @Prop({ type: String, default: 'default' }) private readonly place!:
    | 'default'
    | 'detailImage'
  @Prop({ type: Boolean, default: false })
  private readonly hiddenControls!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hiddenButtons!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly noGutters!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly readonlyCropper!: boolean
  @Prop({ type: String, default: '' })
  private readonly titleOneAllImages!: string
  @Prop({ type: Array, required: true })
  private readonly images!: CropperImageProp[]

  @Prop({ type: String, default: '' })
  private readonly title!: string
  @Prop({ type: String, default: '' })
  private readonly infoText!: string
  @Prop({ type: Number }) private maxSize?: number
  @Prop({ type: Boolean, default: true }) switchEnabled!: boolean
  @Prop({ type: Boolean, default: true }) buttonsEnabled!: boolean
  @Prop({ type: Boolean, default: true }) isActiveCropper!: boolean
  @Prop({ type: Boolean, default: undefined }) oneImageForAllFormats?: boolean
  @Prop({ type: Boolean, default: false })
  private readonly hidePreviews!: boolean
  @Prop({ type: Boolean, default: false })
  private readonly isShowCloseButton!: boolean

  @Ref('cropper') private readonly cropperRef?: CropperInstance

  private isReadyCropper = false
  private cropperKey = Date.now()
  private currentImageIndex = -1
  private localOneImageForAllFormats = false
  private currentZoom = 0
  private initialImages: CropperImageProp[] = []
  private localImages: CropperImageProp[] = []
  private alwaysSelectedPreview = false
  private forceUpdateCropperKey = Date.now()
  private queueSaveRequestKeys: Record<string, Canceler | boolean> = {}
  private errorSaveRequestKeys: string[] = []
  private successSaveRequestKeys: string[] = []

  private updatingInitialImages = false
  private imageSize = {
    width: 666,
    height: 444,
  }

  private visibleArea = {
    width: 666,
    height: 444,
  }

  private get imageContainerAspectRatio() {
    if (this.place === 'detailImage') {
      return 1.5
    }

    if (this.containerAspectRatio) {
      return this.containerAspectRatio
    }

    return this.visibleArea.width / this.visibleArea.height
  }

  private get hasChanges() {
    const oldImages = this.initialImages
    const newImages = this.localImages

    if (newImages.length === 0 || oldImages.length === 0) {
      return false
    }

    const errorSaveRequestKeys = this.errorSaveRequestKeys

    return newImages.some((newImage, index) => {
      const oldImage = oldImages[index]

      return (
        errorSaveRequestKeys.includes(oldImage.key) ||
        this.hasChangesInImageTransform(oldImage, newImage)
      )
    })
  }

  private get currentImage() {
    const image =
      this.localImages[this.currentImageIndex] ||
      this.localImages.find(({ transform: { image_id } }) => !!image_id) ||
      this.localImages[0]

    if (!image) return null

    return image
  }

  private get isDynamicRatio() {
    return !this.currentImage?.aspectRatio
  }

  private get currentOriginalImageUrl() {
    return this.currentImage?.transform?.image?.url
  }

  private get minWidthImage() {
    if (!this.localOneImageForAllFormats) {
      return this.currentImage?.width
    }

    return Math.max(
      ...this.localImages.reduce((acc: number[], { width }) => {
        if (width) {
          acc.push(width)
        }
        return acc
      }, [])
    )
  }

  private get minHeightImage() {
    if (!this.localOneImageForAllFormats) {
      return this.currentImage?.height
    }

    return Math.max(
      ...this.localImages.reduce((acc: number[], { height }) => {
        if (height) {
          acc.push(height)
        }
        return acc
      }, [])
    )
  }

  private get currentAspectRatio() {
    if (!this.currentImage) return

    if (this.place === 'detailImage' && this.currentImageIndex === -1) {
      return this.imageContainerAspectRatio
    }

    return (
      this.currentImage?.aspectRatio ??
      this.imageSize.width / this.imageSize.height
    )
  }

  private get canSaveImage() {
    return !!this.currentImage?.transform.image?.url
  }

  private hasChangesInImageTransform(
    oldCropImage: CropperImageProp,
    newCropImage: CropperImageProp
  ) {
    const {
      crop_h: oldCropH,
      crop_w: oldCropW,
      crop_x: oldCropX,
      crop_y: oldCropY,
      image_id: oldImageId,
    } = oldCropImage.transform

    const {
      crop_h: newCropH,
      crop_w: newCropW,
      crop_x: newCropX,
      crop_y: newCropY,
      image_id: newImageId,
    } = newCropImage.transform

    return (
      oldImageId !== newImageId ||
      [
        [oldCropH, newCropH],
        [oldCropW, newCropW],
        [oldCropX, newCropX],
        [oldCropY, newCropY],
      ].some(([oldVal, newVal]) => {
        oldVal = oldVal || 0
        newVal = newVal || 0

        return Math.abs(oldVal - newVal) >= 2
      })
    )
  }

  private stencilSize({
    boundaries,
  }: {
    boundaries: { width: number; height: number }
  }) {
    if (this.isDynamicRatio && this.cropperRef) {
      const {
        imageSize: { width: imageWidth, height: imageHeight },
      } = this.cropperRef
      const aspectRatio = imageWidth / imageHeight
      const width = Math.ceil(boundaries.height * aspectRatio)

      return {
        width,
        height: boundaries.height,
      }
    }

    return {
      width: boundaries.width,
      height: boundaries.height,
    }
  }

  private defaultBoundaries({ cropper }: { cropper: HTMLDivElement }) {
    return {
      width: cropper.clientWidth,
      height: cropper.clientHeight,
    }
  }

  private defaultSize() {
    if (!this.currentImage || !this.cropperRef) return

    const { imageSize } = this.cropperRef

    if (this.isDynamicRatio) {
      let width = imageSize.width
      let height = imageSize.height

      if (imageSize.width > imageSize.height) {
        width = this.currentImage.transform.crop_w || 0
        height =
          (this.currentImage.transform.crop_w || 0) *
          (imageSize.width / imageSize.height)
      } else {
        height = this.currentImage.transform.crop_h || 0
        width =
          (this.currentImage.transform.crop_h || 0) *
          (imageSize.height / imageSize.width)
      }

      width = Math.round(Math.min(width, imageSize.width))
      height = Math.round(Math.min(height, imageSize.height))

      return {
        width,
        height,
      }
    }

    const { crop_h: cropHeight, crop_w: cropWidth } =
      this.calcDefaultCoordinates({
        aspectRatio: this.currentAspectRatio,
        imgWidth: imageSize.width,
        imgHeight: imageSize.height,
      })

    if (this.currentImageIndex === -1) {
      return {
        width: imageSize.width,
        height: imageSize.height,
      }
    }

    return {
      width: this.currentImage.transform.crop_w || cropHeight,
      height: this.currentImage.transform.crop_h || cropWidth,
    }
  }

  private defaultPosition() {
    if (!this.currentImage || !this.cropperRef) return

    const { boundaries, imageSize } = this.cropperRef

    if (this.isDynamicRatio) {
      const cropX = this.currentImage.transform.crop_x || 0
      const cropY = this.currentImage.transform.crop_y || 0

      const visibleArea = {
        width: 0,
        height: 0,
      }

      let cropWidth = 0
      let cropHeight = 0
      let left = this.currentImage.transform.crop_x || 0
      let top = this.currentImage.transform.crop_y || 0

      if (imageSize.width > imageSize.height) {
        cropHeight =
          (this.currentImage.transform.crop_w || 0) *
          (imageSize.height / imageSize.width)
        visibleArea.width = this.currentImage.transform.crop_w || 0
        visibleArea.height =
          visibleArea.width * (boundaries.height / boundaries.width)

        top = Math.round(
          cropY > 0
            ? (visibleArea.height - cropHeight) / 2 + cropY
            : (imageSize.height - cropHeight) / 2
        )
      } else {
        cropWidth =
          (this.currentImage.transform.crop_h || 0) *
          (imageSize.width / imageSize.height)
        visibleArea.height = this.currentImage.transform.crop_h || 0
        visibleArea.width =
          visibleArea.height * (boundaries.width / boundaries.height)

        left = Math.round(
          cropX > 0
            ? (visibleArea.width - cropWidth) / 2 + cropX
            : (imageSize.width - cropWidth) / 2
        )
      }

      return {
        left: Math.max(left, 0),
        top: Math.max(top, 0),
      }
    }

    const { crop_x: left, crop_y: top } = this.calcDefaultCoordinates({
      aspectRatio: this.currentAspectRatio,
      imgWidth: imageSize.width,
      imgHeight: imageSize.height,
    })

    if (this.currentImageIndex === -1) {
      return {
        top,
        left,
      }
    }

    return {
      top: this.currentImage.transform.crop_y ?? top,
      left: this.currentImage.transform.crop_x ?? left,
    }
  }

  private calcDefaultCoordinates({
    aspectRatio,
    imgHeight,
    imgWidth,
    zoom = 0,
    offsetLeft = 0.5,
    offsetTop = 0.5,
  }: {
    aspectRatio: number | undefined
    imgHeight: number
    imgWidth: number
    zoom?: number
    offsetLeft?: number
    offsetTop?: number
  }): Pick<ImageTransform, 'crop_h' | 'crop_w' | 'crop_x' | 'crop_y'> {
    if (!aspectRatio) {
      return { crop_x: 0, crop_y: 0, crop_w: imgWidth, crop_h: imgHeight }
    }

    let width = Math.min(Math.ceil(imgHeight * aspectRatio), imgWidth)
    let height = Math.min(Math.ceil(imgWidth / aspectRatio), imgHeight)

    width -= Math.round(width * zoom)
    height -= Math.round(height * zoom)

    const top = Math.ceil((imgHeight - height) * offsetTop)
    const left = Math.ceil((imgWidth - width) * offsetLeft)

    return {
      crop_x: left,
      crop_y: top,
      crop_w: width,
      crop_h: height,
    }
  }

  private toggleOneImageForAllFormats(val = !this.localOneImageForAllFormats) {
    this.localOneImageForAllFormats = this.oneImageForAllFormats ?? val
  }

  private onClickPreview(e: Event, index: number) {
    const target = e.target as HTMLElement | null

    if (target?.closest('.v-btn')) return

    this.currentImageIndex =
      !this.alwaysSelectedPreview && this.currentImageIndex === index
        ? -1
        : index
  }

  private onCropPreview(index: number) {
    this.currentImageIndex = index
  }

  private onReplacePreview(index: number) {
    this.currentImageIndex = index

    if (this.currentImageIndex === -1) {
      this.localImages.forEach((image) => {
        image.transform.crop_y = null
        image.transform.crop_x = null
        image.transform.crop_w = null
        image.transform.crop_h = null
        image.transform.image_id = null
        image.transform.image = {
          url: '',
          id: 0,
        }
      })

      this.currentImageIndex = -1
    } else {
      const localImage = this.localImages[this.currentImageIndex]
      localImage.transform.crop_y = null
      localImage.transform.crop_x = null
      localImage.transform.crop_w = null
      localImage.transform.crop_h = null
      localImage.transform.image_id = null

      localImage.transform.image = {
        url: '',
        id: 0,
      }

      this.toggleOneImageForAllFormats(false)
    }
    this.currentZoom = 0
  }

  private onCancel() {
    this.forceUpdateCropper()
    this.$emit('cancel')
  }

  private forceUpdateCropper() {
    this.forceUpdateCropperKey++
  }

  private saveImage(localImage: CropperImageProp) {
    const {
      key: cropImageKey,
      transform: {
        crop_h: cropH,
        crop_w: cropW,
        crop_x: cropX,
        crop_y: cropY,
        image_id: imageId,
        id,
      },
    } = localImage

    const canceler = this.queueSaveRequestKeys[cropImageKey]

    if (typeof canceler === 'boolean') {
      return
    }

    if (typeof canceler === 'function') {
      canceler()
    }

    this.errorSaveRequestKeys = this.errorSaveRequestKeys.filter(
      (key) => key !== cropImageKey
    )

    this.$set(this.queueSaveRequestKeys, cropImageKey, true)

    if (!imageId) {
      this.$nextTick(() => {
        this.$delete(this.queueSaveRequestKeys, cropImageKey)
      })

      return
    }

    const cancelTokenSource = axios.CancelToken.source()

    if (id) {
      this.$set(
        this.queueSaveRequestKeys,
        cropImageKey,
        cancelTokenSource.cancel
      )
    }

    const requestPromise = id
      ? SiriusAPI.image.changeImageTransform({
          id,
          crop_h: cropH,
          crop_w: cropW,
          crop_x: cropX,
          crop_y: cropY,
          image_id: imageId,
          model_id: this.imageTransformModelId,
          item_id: this.imageTransformItemId,
          responseStatusOnly: 1,
          cancelToken: cancelTokenSource.token,
          isShowNotify: false,
        })
      : SiriusAPI.image
          .addImageTransform({
            crop_h: cropH,
            crop_w: cropW,
            crop_x: cropX,
            crop_y: cropY,
            image_id: imageId,
            model_id: this.imageTransformModelId,
            item_id: this.imageTransformItemId,
            isShowNotify: false,
          })
          .then(({ item: { id: imageTransformId } }) => {
            localImage.transform.id = imageTransformId
          })

    requestPromise
      .then(() => {
        this.$delete(this.queueSaveRequestKeys, cropImageKey)

        const hasChanges = [
          {
            oldValue: cropH,
            newValue: localImage.transform.crop_h,
          },
          {
            oldValue: cropW,
            newValue: localImage.transform.crop_w,
          },
          {
            oldValue: cropX,
            newValue: localImage.transform.crop_x,
          },
          {
            oldValue: cropY,
            newValue: localImage.transform.crop_y,
          },
          {
            oldValue: imageId,
            newValue: localImage.transform.image_id,
          },
        ].some(({ oldValue, newValue }) => oldValue !== newValue)

        if (hasChanges) {
          this.saveImage(localImage)
        } else {
          this.successSaveRequestKeys.push(cropImageKey)
          this.notification()
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) return
        this.$delete(this.queueSaveRequestKeys, cropImageKey)
        this.errorSaveRequestKeys.push(cropImageKey)

        this.notification()
      })
      .finally(() => {
        if (isEmpty(this.queueSaveRequestKeys)) {
          this.successSaveRequestKeys = []

          if (this.errorSaveRequestKeys.length) {
            this.$emit('save:error')
          }
        }
      })
  }

  private notification() {
    if (!isEmpty(this.queueSaveRequestKeys)) {
      return
    }

    const successSaveRequestKeys = new Set(this.successSaveRequestKeys)

    const errorSaveRequestKeys = new Set(this.errorSaveRequestKeys)

    let errorMessageText = ''
    let successMessageText = ''

    if (errorSaveRequestKeys.size) {
      errorMessageText +=
        errorSaveRequestKeys.size > 1
          ? 'Ошибка сохранения кропов '
          : 'Ошибка сохранения кропа '

      errorSaveRequestKeys.forEach((key) => {
        const cropImageDesc = this.localImages.find(
          (image) => image.key === key
        )?.description

        if (cropImageDesc) {
          errorMessageText += `«${cropImageDesc}», `
        }
      })

      errorMessageText = errorMessageText.trim().replace(/,$/, '')
    }

    if (successSaveRequestKeys.size) {
      successMessageText += successSaveRequestKeys.size > 1 ? 'Кропы ' : 'Кроп '

      successSaveRequestKeys.forEach((key) => {
        const cropImageDesc = this.localImages.find(
          (image) => image.key === key
        )?.description

        if (cropImageDesc) {
          successMessageText += `«${cropImageDesc}», `
        }
      })

      successMessageText = successMessageText.trim().replace(/,$/, '')

      successMessageText +=
        successSaveRequestKeys.size > 1 ? ' сохранены' : ' сохранен'
    }

    if (successMessageText) {
      this.$notify({
        type: 'success',
        title: successMessageText,
        autoCloseTime: 7e3,
      })
    }

    if (errorMessageText) {
      this.$notify({
        type: 'error',
        title: errorMessageText,
        autoCloseTime: 0,
      })
    }
  }

  private onSave() {
    for (const localImage of this.localImages) {
      const initialImage = this.initialImages.find(
        (image) => image.key === localImage.key
      )

      if (
        initialImage &&
        !this.errorSaveRequestKeys.includes(initialImage.key) &&
        !this.hasChangesInImageTransform(initialImage, localImage)
      ) {
        continue
      }

      this.saveImage(localImage)
    }

    this.initialImages = nestedFreeze(cloneObject(this.localImages))

    this.$emit('save:local', this.localImages)

    if (this.currentImageIndex === -1) {
      const defaultImageIndex = this.initialImages.findIndex(
        (image) => image.aspectRatio === this.imageContainerAspectRatio
      )
      this.currentImageIndex = defaultImageIndex > -1 ? defaultImageIndex : 0
    }
  }

  private onChange({
    coordinates,
    image,
    visibleArea,
  }: {
    coordinates: CropperImageCoordinates
    image: CropperImage
    visibleArea?: CropperVisibleArea
  }) {
    if (!this.currentImage || !visibleArea || !this.cropperRef) return

    const currentLocalImage = this.localImages[this.currentImageIndex]

    const { imageSize } = this.cropperRef

    if (this.isDynamicRatio && this.cropperRef && currentLocalImage) {
      currentLocalImage.transform.crop_h = Math.round(
        Math.min(visibleArea.height, imageSize.height)
      )

      currentLocalImage.transform.crop_w = Math.round(
        Math.min(visibleArea.width, imageSize.width)
      )

      currentLocalImage.transform.crop_x = Math.round(
        Math.max(visibleArea.left, 0)
      )

      currentLocalImage.transform.crop_y = Math.round(
        Math.max(visibleArea.top, 0)
      )

      this.currentZoomChange()
      return
    }

    const { width, height } = coordinates
    const left = Math.round(Math.max(coordinates.left, 0))
    const top = Math.round(Math.max(coordinates.top, 0))
    let imageForAllFormats: Image | null = null

    if (this.localOneImageForAllFormats) {
      imageForAllFormats =
        this.currentImage.transform.image ||
        this.localImages.find(({ transform: { image } }) => image?.id)
          ?.transform.image ||
        null
    }

    this.localImages.forEach((localImage, imageIndex) => {
      const transformImage = imageForAllFormats || localImage.transform.image

      if (this.currentImageIndex === imageIndex) {
        localImage.transform.crop_x = left
        localImage.transform.crop_y = top
        localImage.transform.crop_w = width
        localImage.transform.crop_h = height
      } else if (
        this.currentImageIndex === -1 ||
        (this.localOneImageForAllFormats &&
          localImage.transform.image?.id !== transformImage?.id)
      ) {
        const reversed = Math.abs(image.transforms.rotate) % 180 > 0

        const imageWidth = reversed ? image.height : image.width
        const imageHeight = reversed ? image.width : image.height

        let offsetLeft = left / (imageWidth - width)
        let offsetTop = top / (imageHeight - height)

        if (Number.isNaN(offsetLeft)) {
          offsetLeft = 0.5
        }

        if (Number.isNaN(offsetTop)) {
          offsetTop = 0.5
        }

        const { crop_x, crop_y, crop_w, crop_h } = this.calcDefaultCoordinates({
          aspectRatio: localImage.aspectRatio,
          imgWidth: imageWidth,
          imgHeight: imageHeight,
          zoom: this.currentZoom,
          offsetLeft,
          offsetTop,
        })

        localImage.transform.crop_x = crop_x
        localImage.transform.crop_y = crop_y
        localImage.transform.crop_w = crop_w
        localImage.transform.crop_h = crop_h
      }

      localImage.transform.image = transformImage || null
      localImage.transform.image_id = transformImage?.id || null
    })

    this.imageSize.width = image.width
    this.imageSize.height = image.height

    this.visibleArea.width = Math.floor(visibleArea.width)
    this.visibleArea.height = Math.floor(visibleArea.height)
    this.currentZoomChange()
  }

  private currentZoomChange() {
    const cropper = this.cropperRef
    if (cropper) {
      const { coordinates, imageSize } = cropper

      if (
        imageSize.width / imageSize.height >
        coordinates.width / coordinates.height
      ) {
        this.currentZoom =
          (cropper.imageSize.height - cropper.coordinates.height) /
          (cropper.imageSize.height - cropper.sizeRestrictions.minHeight)
      } else {
        this.currentZoom =
          (cropper.imageSize.width - cropper.coordinates.width) /
          (cropper.imageSize.width - cropper.sizeRestrictions.minWidth)
      }
    }
  }

  private get controlsConfig() {
    return [
      {
        tooltip: 'Поворот против часовой',
        icon: 'mdi-rotate-left',
        action: 'rotate',
        params: { angle: -90 },
        enabled: false,
      },
      {
        tooltip: 'Поворот по часовой',
        icon: 'mdi-rotate-right',
        action: 'rotate',
        params: { angle: 90 },
        enabled: false,
      },
      {
        tooltip: 'Отразить по горизонтали',
        icon: 'mdi-swap-horizontal',
        action: 'flip',
        params: { vertical: false, horizontal: true },
        enabled: false,
      },
      {
        tooltip: 'Отразить по вертикали',
        icon: 'mdi-swap-vertical',
        action: 'flip',
        params: { vertical: true, horizontal: false },
        enabled: false,
      },
      {
        tooltip:
          this.localOneImageForAllFormats && this.currentImageIndex === -1
            ? 'Удалить все картинки'
            : 'Удалить',
        icon: 'mdi-delete-outline',
        action: 'reset',
        params: null,
      },
    ].filter(({ enabled = true }) => enabled)
  }

  private onLoadStart() {
    this.$emit('load:start')
  }

  private onLoadImage({ url, id, width, height, ...payload }: SizeImage) {
    if (!this.currentImage) return

    this.imageSize.width = width
    this.imageSize.height = height

    this.currentZoom = 0

    if (this.currentImageIndex > -1 && !this.oneImageForAllFormats) {
      const { crop_h, crop_w, crop_y, crop_x } = this.calcDefaultCoordinates({
        aspectRatio: this.currentAspectRatio,
        imgWidth: width,
        imgHeight: height,
      })

      const localImage = this.localImages[this.currentImageIndex]

      localImage.transform.image = {
        url,
        id,
      }
      localImage.transform.image_id = id
      localImage.transform.crop_h = crop_h
      localImage.transform.crop_w = crop_w
      localImage.transform.crop_x = crop_x
      localImage.transform.crop_y = crop_y
    } else {
      this.localImages.forEach((localImage) => {
        const { crop_h, crop_w, crop_y, crop_x } = this.calcDefaultCoordinates({
          aspectRatio: localImage?.aspectRatio,
          imgWidth: width,
          imgHeight: height,
        })

        localImage.transform.image = {
          url,
          id,
        }
        localImage.transform.image_id = id
        localImage.transform.crop_h = crop_h
        localImage.transform.crop_w = crop_w
        localImage.transform.crop_x = crop_x
        localImage.transform.crop_y = crop_y
      })
    }

    this.$emit('load:image', {
      ...payload,
      url,
      id,
      width,
      height,
      key: this.currentImage.key,
    })
  }

  private onZoom(value: number) {
    value = value / 100

    const cropper = this.cropperRef
    if (cropper) {
      if (cropper.imageSize.height < cropper.imageSize.width) {
        const minHeight = cropper.sizeRestrictions.minHeight
        const imageHeight = cropper.imageSize.height

        cropper.zoom(
          (imageHeight - this.currentZoom * (imageHeight - minHeight)) /
            (imageHeight - value * (imageHeight - minHeight))
        )
      } else {
        const minWidth = cropper.sizeRestrictions.minWidth
        const imageWidth = cropper.imageSize.width
        cropper.zoom(
          (imageWidth - this.currentZoom * (imageWidth - minWidth)) /
            (imageWidth - value * (imageWidth - minWidth))
        )
      }
    }
  }

  private flip(params: Record<string, boolean>) {
    this.cropperRef?.flip(params.horizontal, params.vertical)
  }

  private rotate(params: Record<string, number>) {
    this.cropperRef?.rotate(params.angle)
  }

  private controlAction(
    action: string,
    params: Record<string, boolean | number> | null
  ) {
    switch (action) {
      case 'reset':
        this.onReplacePreview(this.currentImageIndex)
        break
      case 'flip':
        this.flip(params as Record<string, boolean>)
        break
      case 'rotate':
        this.rotate(params as Record<string, number>)
        break
      // default:
      //   this.move(action)
    }
  }

  private closeCropper() {
    this.$emit('close')
  }

  private created() {
    const initImages = debounce(() => {
      if (Object.keys(this.queueSaveRequestKeys).length > 0) {
        return
      }

      this.initialImages = nestedFreeze(cloneObject(this.images))

      this.alwaysSelectedPreview =
        this.selectedPreview ||
        this.initialImages.some(
          ({ transform: { crop_h, crop_w, image } }) =>
            image?.id && crop_h && crop_h > 0 && crop_w && crop_w > 0
        )

      this.toggleOneImageForAllFormats(
        this.initialImages.every(
          (image) =>
            image.transform.image?.id ===
            this.initialImages[0].transform.image?.id
        )
      )

      this.localImages = cloneObject(this.initialImages)

      this.updatingInitialImages = false

      this.$nextTick().finally(() => {
        this.cropperKey++
      })
    }, 100)

    this.$watch(
      () => {
        return this.images.reduce(
          (
            acc,
            { transform: { image_id, crop_h, crop_w, crop_x, crop_y } }
          ) => {
            acc += `-${image_id}-${crop_h}-${crop_w}-${crop_x}-${crop_y}-`

            return acc
          },
          ''
        )
      },
      () => {
        this.updatingInitialImages = true
      }
    )

    this.$watch(() => {
      return this.forceUpdateCropperKey
    }, initImages)

    this.$watch(
      () => {
        return [
          Object.keys(this.queueSaveRequestKeys).length > 0,
          this.updatingInitialImages,
        ].join('-')
      },
      () => {
        if (this.updatingInitialImages || !this.initialImages.length) {
          initImages()
        }
      },
      { immediate: true }
    )

    this.$once('hook:beforeDestroy', () => {
      initImages.cancel()
    })
  }

  private mounted() {
    this.$watch(
      () => {
        return [this.value, this.alwaysSelectedPreview].join('-')
      },
      () => {
        if (this.value > -1) {
          this.currentImageIndex = this.value
        } else if (this.alwaysSelectedPreview) {
          const defaultImageIndex = this.initialImages.findIndex(
            (image) => image.aspectRatio === this.imageContainerAspectRatio
          )

          this.currentImageIndex =
            defaultImageIndex > -1 ? defaultImageIndex : 0
        } else {
          this.currentImageIndex = -1
        }
      },
      { immediate: true }
    )

    const saveCroppers = () => {
      this.localImages.forEach((localImage) => {
        const newImage = cloneObject(localImage)

        if (!localImage.transform.image_id) {
          newImage.transform.id = null
        }

        this.$emit('save', newImage)
      })
    }

    this.$watch(
      () => {
        return !Object.keys(this.queueSaveRequestKeys).length
      },
      (finished) => {
        if (!finished) return

        saveCroppers()

        this.$emit('save:success', this.localImages)
      }
    )

    this.$watch(
      () => {
        return [
          Object.keys(this.queueSaveRequestKeys).length,
          this.updatingInitialImages,
        ].join('-')
      },
      () => {
        const loading = Object.keys(this.queueSaveRequestKeys).length > 0

        if (this.updatingInitialImages) {
          if (loading) {
            this.$emit('update:loading', true)
            this.$emit('update:saving', true)
          }

          return
        }

        this.$emit('update:loading', loading)
        this.$emit('update:saving', loading)
      }
    )

    this.$watch('currentImageIndex', (newIndex: number) => {
      this.$emit('change', newIndex)
    })

    this.$watch(
      () => {
        return this.cropperKey
      },
      () => {
        this.isReadyCropper = false
      }
    )

    this.$watch(
      () => {
        return this.isReadyCropper
      },
      () => {
        if (!this.isReadyCropper) return
        this.$emit('ready')
      }
    )
  }

  private beforeDestroy() {
    this.$emit('update:loading', false)
    this.$emit('update:saving', false)
  }
}
