import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { deleteSettingsPopup } from '@/config/settingsPopup'
import { userModule } from '@/store/namespaces'
import { UserGetters } from '@/store/modules/user/types'

@Component({
  inheritAttrs: false,
})
export default class DataTable extends Vue {
  @Prop({ type: Array, required: true }) private readonly headers!: {
    value: string
    align?: string
  }[]
  @Prop({ type: String, default: 'id' }) private readonly viewItemKey!: string
  @Prop({ type: Boolean, default: false }) private hideViewItem!: boolean
  @Prop({ type: Boolean, default: false }) private hideEditItem!: boolean
  @Prop({ type: Boolean, default: false }) private widgetSelect!: boolean
  @Prop({ type: Boolean, default: false }) private hideFooter!: boolean
  @Prop({ type: Boolean, default: undefined })
  private savedOptionsInUrl?: boolean

  @userModule.Getter private readonly userIsAdmin!: UserGetters['userIsAdmin']

  @Prop({ type: String, default: 'Редактировать' })
  private editTooltipText!: string
  @Prop({ type: Boolean, default: false })
  private hiddenEditTooltipText!: boolean
  @Prop({ type: String, default: 'Просмотр на сайте' })
  private viewTooltipText!: string
  @Prop({ type: Number, default: 143 })
  private readonly editTooltipNudeRight!: number
  @Prop({ type: Number, default: 170 })
  private readonly viewTooltipNudeRight!: number
  @Prop({ type: Boolean, default: null })
  private readonly disabledDelete!: boolean
  @Prop({ type: Number }) private readonly page?: number
  @Prop({ type: Number }) private readonly itemsPerPage?: number

  private localPage = 1
  private localItemsPerPage = 10

  private get localListeners() {
    const $listeners = { ...this.$listeners }

    delete $listeners['update:page']
    delete $listeners['update:items-per-page']

    return $listeners
  }

  private get localDisabledDelete() {
    return this.disabledDelete ?? !this.userIsAdmin
  }

  private get lastSlotName() {
    const lastHeader =
      this.headers.length > 1 ? this.headers[this.headers.length - 1] : null

    return lastHeader?.value
      ? {
          item: `item.${lastHeader.value}`,
          header: `header.${lastHeader.value}`,
        }
      : null
  }

  private get popUpDeleteSettings() {
    return deleteSettingsPopup
  }

  private deleteTitle(item: any) {
    // ToDo: продумать, как передавать динамически через родительский компонент
    const name =
      item.name ||
      item.title ||
      [item.first_name, item.last_name].filter((v) => !!v).join(' ') ||
      item.email ||
      item?.article?.name

    return name?.replace(/\?$/, '')
  }

  private get isWidget() {
    return this.$route.name === 'content_article'
  }

  private get localScopedSlots() {
    const scopedSlots = { ...this.$scopedSlots }

    if (this.lastSlotName) {
      if (!scopedSlots[this.lastSlotName.item]) {
        scopedSlots[this.lastSlotName.item] = undefined
      }

      if (!scopedSlots[this.lastSlotName.header]) {
        scopedSlots[this.lastSlotName.header] = undefined
      }
    }

    return scopedSlots
  }

  private created() {
    this.$watch(
      () => {
        return [this.page, this.itemsPerPage].join('-')
      },
      () => {
        this.localPage = this.page ?? this.localPage
        this.localItemsPerPage = this.itemsPerPage ?? this.localItemsPerPage
      },
      {
        immediate: true,
      }
    )
  }

  private onUpdatePage(value: number) {
    if (this.localPage === value) {
      return
    }

    this.localPage = value
    this.$emit('update:page', value)
  }

  private onUpdateItemsPerPage(value: number) {
    if (this.localItemsPerPage === value) {
      return
    }

    this.localItemsPerPage = value
    this.$emit('update:items-per-page', value)
  }
}
